import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

var copyspeed = marqueespeed
var pausespeed = (pauseit == 0) ? copyspeed : 0
var actualheight = ''

// function scrollmarquee(){
// if (parseInt(cross_marquee.style.top)>(actualheight*(-1)+8)) //if scroller hasn't reached the end of its height
// cross_marquee.style.top=parseInt(cross_marquee.style.top)-copyspeed+"px" //move scroller upwards
// else //else, reset to original position
// cross_marquee.style.top=parseInt(marqueeheight)+8+"px"
// return cross_marquee
// }

// function initializemarquee(){
// cross_marquee=document.getElementById("vmarquee")
// cross_marquee.style.top=0
// marqueeheight=document.getElementById("marqueecontainer").offsetHeight
// actualheight=cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
// if (window.opera || navigator.userAgent.indexOf("Netscape/7")!=-1){ //if Opera or Netscape 7x, add scrollbars to scroll and exit
// cross_marquee.style.height=marqueeheight+"px"
// cross_marquee.style.overflow="scroll"
// return cross_marquee
// }
// setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
// window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
// window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
// window.onload=initializemarquee


function checkInput() {
  var len = document.getElementById('search-text').value.length;
  if (len < 1) {
    alert('Please enter a search value');
    return false;
  }
}



class About extends Component {
  state = {
    viewCompleted: false,
    activeItem: {
      title: "",
      description: "",
      completed: false
    },
    todoList: []
  };

  async componentDidMount() {
    try {
      const res = await fetch('http://13.234.5.219:8000/api/todos/');
      const todoList = await res.json();
      this.setState({
        todoList
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  //Responsible for saving the task
  handleSubmit = item => {
    this.toggle();
    if (item.id) {
      axios
        .put(`http://13.234.5.219:8000/api/todos/${item.id}/`, item)
      return;
    }
    axios
      .post("http://13.234.5.219:8000/api/todos/", item)
  };

  createItem = () => {
    const item = { title: "", description: "", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };

  displayCompleted = status => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }
    return this.setState({ viewCompleted: false });
  };
  renderTabList = () => {
    return (
      <div className="my-5 tab-list">
        <button
          onClick={() => this.displayCompleted(true)}
          className={this.state.viewCompleted ? "active" : ""}
        >
          Complete
        </button>
        <button
          onClick={() => this.displayCompleted(false)}
          className={this.state.viewCompleted ? "" : "active"}
        >
          Incomplete
        </button>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      item => item.completed === viewCompleted
    );
    return newItems.map(item => (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-center"
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
            }`}
          title={item.description}
        >
          {item.title}
        </span>
      </li>
    ));
  };

  render() {
    return (




      <div id="wrapper" class="wrapper" >
        {/* {this.props.children} */}

        <div id="content">
          {/* <!-- begin featured --> */}

          {/* <!-- end featured --> */}
          {/* <!-- begin recent posts --> */}
          <div class="recent">
            {/* <!-- begin post --> */}
            <div class="post">
              <h1>Plant and Environment Stress</h1>
              <p align="justify">Land plants are frequently exposed to a variety of undesirable, or even adverse,
                environmental conditions due to their sessile nature. Abiotic (salinity, drought, heat, cold,
                heavy metals, ozone, UV radiation, and nutritional deficits) and biotic (microbes, insects, and
                herbivores) stressors are key restrictions that affect plant development, growth, and
                reproduction and pose a major threat to the lives of plants. The catastrophic forecast of global
                warming, which is expected to have a negative impact on crop yield and thus on food
                production, is expected to increase the frequency, intensity, and duration of these adverse
                environmental conditions. The development of stress-tolerant cultivars with higher yields is
                at the top of the priority list for meeting future food and nutrition needs. Comprehensive
                knowledge about the molecular mechanism of stress tolerance would be the initial step
                towards the development of stress tolerant crops.</p>




            </div>

            <div class="post">
              <h1>Plant Environment Stress Database</h1>
              <p align="justify">There are few available databases that contain meagre information on stress responsive genes
                namely- Plant Stress Gene Database, STIFDB, Arabidopsis stress responsive gene database
                etc. As they lack complete genome-wide information about the genes such as- information
                about regulatory elements, sequence (gene/CDS/Protein) information, genomic variants
                (SNPs) and structural information. There are studies reported that explored transcriptional or
                post-transcriptional regulatory interactions (Palaniswamy et al. 2006; Chien et al. 2015;
                Wilkins et al. 2016) ; still the integrated perspective of regulatory network is lacking. The
                present effort involved construction of highly curated authentic database named Plant
                environmental stress gene Database (<b>PlantESdb</b>), which contains comprehensive, high
                quality resources for the individual as well as combined environmental stresses responsive
                genes (ESR genes) and their transcriptional and post-transcriptional regulatory networks in
                Arabidopsis and maize from published literature and various genomic databases with an
                effective knowledge based information retrieval system.</p>




            </div>
            {/* <!-- end post --> */}
          </div>
          {/* <!-- end recent posts --> */}
        </div>

        <Sidebar />

      </div>

    )
  }
}

export default About;
