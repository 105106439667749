import React, { Component } from "react"

// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
// import Sidebar from "./Sidebar";


import Cytoscape from 'cytoscape';
import COSEBilkent from 'cytoscape-cose-bilkent';
// import React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
// import cola from 'cytoscape-cola';
var cola = require('cytoscape-cola/cytoscape-cola.js');
Cytoscape.use(COSEBilkent);



// import fcose from 'cytoscape-fcose';

// Cytoscape.use( fcose );


Cytoscape.use(cola);
// 
var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

var copyspeed = marqueespeed
var pausespeed = (pauseit == 0) ? copyspeed : 0
var actualheight = ''

const options = {
  name: 'grid',

  fit: true, // whether to fit the viewport to the graph
  padding: 30, // padding used on fit
  boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
  avoidOverlap: true, // prevents node overlap, may overflow boundingBox if not enough space
  avoidOverlapPadding: 10, // extra spacing around nodes when avoidOverlap: true
  nodeDimensionsIncludeLabels: false, // Excludes the label when calculating node bounding boxes for the layout algorithm
  spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
  condense: false, // uses all available space on false, uses minimal space on true
  rows: undefined, // force num of rows in the grid
  cols: undefined, // force num of columns in the grid
  position: function (node) { }, // returns { row, col } for element
  sort: undefined, // a sorting function to order the nodes; e.g. function(a, b){ return a.data('weight') - b.data('weight') }
  animate: false, // whether to transition the node positions
  animationDuration: 500, // duration of animation in ms if enabled
  animationEasing: undefined, // easing of animation if enabled
  animateFilter: function (node, i) { return true; }, // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
  ready: undefined, // callback on layoutready
  stop: undefined, // callback on layoutstop
  transform: function (node, position) { return position; } // transform a given node position. Useful for changing flow direction in discrete layouts 
};
// function scrollmarquee(){
// if (parseInt(cross_marquee.style.top)>(actualheight*(-1)+8)) //if scroller hasn't reached the end of its height
// cross_marquee.style.top=parseInt(cross_marquee.style.top)-copyspeed+"px" //move scroller upwards
// else //else, reset to original position
// cross_marquee.style.top=parseInt(marqueeheight)+8+"px"
// return cross_marquee
// }

// function initializemarquee(){
// cross_marquee=document.getElementById("vmarquee")
// cross_marquee.style.top=0
// marqueeheight=document.getElementById("marqueecontainer").offsetHeight
// actualheight=cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
// if (window.opera || navigator.userAgent.indexOf("Netscape/7")!=-1){ //if Opera or Netscape 7x, add scrollbars to scroll and exit
// cross_marquee.style.height=marqueeheight+"px"
// cross_marquee.style.overflow="scroll"
// return cross_marquee
// }
// setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
// window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
// window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
// window.onload=initializemarquee


function checkInput() {
  var len = document.getElementById('search-text').value.length;
  if (len < 1) {
    alert('Please enter a search value');
    return false;
  }
}



class CytoscapeGraph extends Component {
  state = {
    viewCompleted: false,
    activeItem: {
      title: "",
      description: "",
      completed: false,


    },
    species: 'arab',
    properties: { 'closeness_centrality': {}, 'betweenness': {}, 'nodes': '--', 'avg_path_len': '--', 'connected': '--', 'edges': '--', 'avg_diam': '--', 'g_cluster': '--', 'avg_clust_coff': '--', 'avg_deg': '--' },
    gene: "('GRMZM2G008252')",
    stress: 'cold',
    elements: {
      'nodes': [
        { data: { id: 'AT-MIR394B', label: 'AT-MIR394B' }, position: { x: 0, y: 0 } },
        { data: { id: 'AT-MIR172C', label: 'AT-MIR172C' }, position: { x: 100, y: 0 } }
      ],
      'edges': [
        {
          data: { 'source': 'AT-MIR394B', 'target': 'AT-MIR172C', 'label': 'Edge from Node1 to Node2' }
        }
      ]
    },
    todoList: []
  };

  async componentDidMount() {
    try {
      // const res = await fetch('http://13.234.5.219:8000/api/todos/');
      // const todoList = await res.json();
      // this.setState({
      //   todoList
      // });
      var filter_data = { 'status': 'cytoscape_without_stress', 'species': this.state.species, 'gene': this.state.gene, 'stress': this.state.stress };
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
        console.log('Dataval', resp)

        this.setState({ elements: resp.data.data, properties: resp.data.properties })
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };


  handleChange = (event) => { this.setState({ species: event.target.value }); }
  handleChange2 = (event) => { this.setState({ stress: event.target.value }); }
  handleChange3 = (event) => { this.setState({ gene: event.target.value }); }
  handleSubmit = (event) => {
    alert('A name was submitted: ' + this.state.species);
    event.preventDefault();

    try {
      // const res = await fetch('http://13.234.5.219:8000/api/todos/');
      // const todoList = await res.json();
      // this.setState({
      //   todoList
      // });
      var filter_data = { 'status': 'cytoscape_without_stress', 'species': this.state.species, 'gene': this.state.gene, 'stress': this.state.stress };
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
        console.log('Dataval2', resp)

        this.setState({ elements: resp.data.data, properties: resp.data.properties })
      });
    } catch (e) {
      console.log(e);
    }
  }
  //Responsible for saving the task
  // handleSubmit = item => {
  //   this.toggle();
  //   if (item.id) {
  //     axios
  //       .put(`http://13.234.5.219:8000/api/todos/${item.id}/`, item)
  //     return;  
  //   }
  //   axios
  //     .post("http://13.234.5.219:8000/api/todos/", item)
  // };

  createItem = () => {
    const item = { title: "", description: "", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };

  displayCompleted = status => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }
    return this.setState({ viewCompleted: false });
  };
  renderTabList = () => {
    return (
      <div className="my-5 tab-list">
        <button
          onClick={() => this.displayCompleted(true)}
          className={this.state.viewCompleted ? "active" : ""}
        >
          Complete
        </button>
        <button
          onClick={() => this.displayCompleted(false)}
          className={this.state.viewCompleted ? "" : "active"}
        >
          Incomplete
        </button>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      item => item.completed === viewCompleted
    );
    return newItems.map(item => (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-center"
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
            }`}
          title={item.description}
        >
          {item.title}
        </span>
      </li>
    ));
  };


  render() {


    // const elements = [
    //   { data: { id: 'one', label: 'Node 1' }, position: { x: 0, y: 0 } },
    //   { data: { id: 'two', label: 'Node 2' }, position: { x: 100, y: 0 } },
    //   { data: { source: 'one', target: 'two', label: 'Edge from Node1 to Node2' } }
    // ];

    const layout = { name: 'cose-bilkent' };
    let chunkedPartsArray = this.state.properties.betweenness;

    return (

      <div id="wrapper" class="wrapper" >
        {/* {this.props.children} */}

        <div id="content">
          {/* <!-- begin featured --> */}
          <div id="featured" style={{ height: '800px' }}>
            <div class="post">
              <h1><a href="#">Welcome to PlantESdb</a></h1>
              <br /><br />
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div class="col-md-4">
                    <label style={{ fontSize: '22px' }}>
                      Select Species: &nbsp; &nbsp; &nbsp;
                      <select value={this.state.species} onChange={this.handleChange} style={{ fontSize: '22px', height: '30px', width: '250px' }}>            <option value="species">Species</option>
                        <option value="maize">Maize</option>
                        <option value="arab">Arabidopsis</option>
                      </select>
                    </label>

                  </div>
                  <br />
                  {/* <div class="col-md-4">
                    <label>
                      Select Species:
                      <select value={this.state.stress} onChange={this.handleChange2}>

                        <option value="cold">Cold</option>
                        <option value="drought">Drought</option>
                        <option value="salt">Salt</option>
                        <option value="wl">Water Logging</option>
                      </select>
                    </label>

                  </div> */}
                  <div class="col-md-4">
                    <label style={{ fontSize: '22px' }}>
                      Genes: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <input type="text" value={this.state.gene} onChange={this.handleChange3} style={{ fontSize: '22px', height: '30px', width: '250px' }} />        </label>

                  </div>
                  <br />

                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <input type="submit" value="Submit" style={{ fontSize: '22px', height: '30px', width: '250px' }} />
                </div>

              </form>

              <CytoscapeComponent

                elements={CytoscapeComponent.normalizeElements(this.state.elements)}
                layout={{ 'name': 'cose' }}
                animate={{
                  pan: { x: 500, y: 250 },
                  zoom: 2
                }, {
                  duration: 1000
                }} style={{ width: '1255px', height: '600px', 'curve-style': 'bezier' }}
              />
              {/* <b>(eg. HGNC Gene Symbol : <a href="http://lms.snu.edu.in/corecg/searchresultt.php?ID=9">NAT1</a> , Entrez Gene ID :<a href="http://lms.snu.edu.in/corecg/searchresultt.php?ID=9">9</a>)</b><br/><br/><br/> */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/adv_s.php">Advance Search</a></b>&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/seq_s.php">Sequence Search</a></b>&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/pubmed_id_s.php">Pubmed Search</a></b> */}
              <br />
              <br />        <div class="break"></div>
            </div>
          </div>
          {/* <!-- end featured --> */}
          {/* <!-- begin recent posts --> */}

          {/* <!-- end recent posts --> */}
        </div>

        {/* <Sidebar /> */}
        <div id="sidebar">
          {/* <!-- begin popular posts --> */}


          <div class="box">
            <h2>Network Properties</h2>
            <ul class="popular">
              <table style={{ border: '1px black', width: '100%', borderCollapse: 'collapse' }} border="1"><colgroup><col width="50%" />
              </colgroup><tbody><tr>
                <td style={{ padding: '3px' }}><b>No. of nodes</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.nodes}</center></td>
              </tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>No. of edges</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.edges}</center></td>
                  </tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>Average Diameter</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.avg_diam}</center></td>
                  </tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>Average clustering coefficient</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.avg_clust_coff}</center></td></tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>Average Path Length</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.avg_path_len}</center></td></tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>Connected Graph?</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.connected}</center></td>
                  </tr>
                  <tr><td style={{ padding: '3px' }}><b>Averge Degree</b></td><td style={{ padding: '3px' }}><center>{this.state.properties.avg_deg}</center></td></tr>
                  <tr>
                    <td style={{ padding: '3px' }}><b>Betweenness</b></td><td style={{ padding: '3px' }}>
                      <center>

                        {
                          Object.entries(chunkedPartsArray)
                            .map(([key, value]) => {

                              // let t = value
                              // return {value}

                              return (<tr><td>{key} &nbsp;&nbsp;&nbsp; </td><td>{value.toFixed(2)} </td></tr>)


                            })


                        }

                      </center>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: '3px' }}><b>Closeness Centrality</b></td><td style={{ padding: '3px' }}>
                      <center>

                        {
                          Object.entries(this.state.properties.closeness_centrality)
                            .map(([key, value]) => {

                              // let t = value
                              // return {value}

                              return (<tr><td>{key} &nbsp;&nbsp;&nbsp; </td><td>{value.toFixed(2)} </td></tr>)


                            })


                        }

                      </center>
                    </td>
                  </tr>
                </tbody></table>
            </ul>
          </div>


        </div>

      </div>


    );

  }
}

export default CytoscapeGraph;
