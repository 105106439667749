import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, About, Contact, Cytoscape, JBrowser, FAQ, Teams, Statistics, SubmitData, PubmedSearch } from "./components";
import Blast from "./components/Blast";
import SearchResult from "./components/SearchResults";
// import Teams from "./components/Teams";
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/contact" exact component={() => <Contact />} />
          <Route path="/searchresults" exact component={() => <SearchResult />} />
          <Route path="/cytoscape" exact component={() => <Cytoscape />} />
          <Route path="/gbrowser" exact component={() => <JBrowser />} />
          <Route path="/blast" exact component={() => <Blast />} />
          <Route path="/faq" exact component={() => <FAQ />} />
          <Route path="/teams" exact component={() => <Teams />} />
          <Route path="/statistics" exact component={() => <Statistics />} />
          <Route path="/submitdata" exact component={() => <SubmitData />} />
          <Route path="/pubmedsearch" exact component={() => <PubmedSearch />} />

        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
