import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

var copyspeed = marqueespeed
var pausespeed = (pauseit == 0) ? copyspeed : 0
var actualheight = ''

// function scrollmarquee(){
// if (parseInt(cross_marquee.style.top)>(actualheight*(-1)+8)) //if scroller hasn't reached the end of its height
// cross_marquee.style.top=parseInt(cross_marquee.style.top)-copyspeed+"px" //move scroller upwards
// else //else, reset to original position
// cross_marquee.style.top=parseInt(marqueeheight)+8+"px"
// return cross_marquee
// }

// function initializemarquee(){
// cross_marquee=document.getElementById("vmarquee")
// cross_marquee.style.top=0
// marqueeheight=document.getElementById("marqueecontainer").offsetHeight
// actualheight=cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
// if (window.opera || navigator.userAgent.indexOf("Netscape/7")!=-1){ //if Opera or Netscape 7x, add scrollbars to scroll and exit
// cross_marquee.style.height=marqueeheight+"px"
// cross_marquee.style.overflow="scroll"
// return cross_marquee
// }
// setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
// window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
// window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
// window.onload=initializemarquee


function checkInput() {
  var len = document.getElementById('search-text').value.length;
  if (len < 1) {
    alert('Please enter a search value');
    return false;
  }
}



class App extends Component {
  state = {
    viewCompleted: false,
    activeItem: {
      title: "",
      description: "",
      completed: false
    },
    todoList: []
  };

  async componentDidMount() {
    try {
      // var filter_data = {'inputs':'Inside inputs'};
      // const res = await fetch('http://13.234.5.219:8000/api/esanjeevni');
      // console.log('Data',res)
      // const todoList = await res.json();
      // console.log('Data',todoList)
      // this.setState({
      //   todoList
      // });
      //       var filter_data = {'input':'inputs'};
      // axios.post('http://13.234.5.219:8000/api/esanjeevni',filter_data).then(resp => {
      //   console.log('Data',resp)
      // });
      // axios.post(ESANJEEVNI,filter_data).then(resp => {
      //   console

      // });
    } catch (e) {
      console.log(e);
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  //Responsible for saving the task
  handleSubmit = item => {
    this.toggle();
    if (item.id) {
      axios
        .put(`http://13.234.5.219:8000/api/todos/${item.id}/`, item)
      return;
    }
    axios
      .post("http://13.234.5.219:8000/api/todos/", item)
  };

  createItem = () => {
    const item = { title: "", description: "", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };

  displayCompleted = status => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }
    return this.setState({ viewCompleted: false });
  };
  renderTabList = () => {
    return (
      <div className="my-5 tab-list">
        <button
          onClick={() => this.displayCompleted(true)}
          className={this.state.viewCompleted ? "active" : ""}
        >
          Complete
        </button>
        <button
          onClick={() => this.displayCompleted(false)}
          className={this.state.viewCompleted ? "" : "active"}
        >
          Incomplete
        </button>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      item => item.completed === viewCompleted
    );
    return newItems.map(item => (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-center"
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
            }`}
          title={item.description}
        >
          {item.title}
        </span>
      </li>
    ));
  };

  render() {
    return (
      <div id="wrapper" class="wrapper" >
        {/* {this.props.children} */}

        <div id="content">
          {/* <!-- begin featured --> */}
          <div id="featured">
            <div class="post">
              <h1><a href="#">Welcome to PlantESdb</a></h1>
              <br /><br />
              <form method="post" action="searchresult.php">

                <select id="search-sel" name="selSearchType">
                  <option value="Gene_Symbol" selected="selected">Species</option>
                  <option value="Gene_ID">Arabidopsis</option>
                  <option value="Chromosome">Maize</option>
                </select>
                <input type="text" id="search-text" name="searchInput" />
                {/* <input type="submit" id="search-submit" value="Search" onclick="return checkInput();"/> */}
              </form><br />
              {/* <b>(eg. HGNC Gene Symbol : <a href="http://lms.snu.edu.in/corecg/searchresultt.php?ID=9">NAT1</a> , Entrez Gene ID :<a href="http://lms.snu.edu.in/corecg/searchresultt.php?ID=9">9</a>)</b><br/><br/><br/> */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/adv_s.php">Advance Search</a></b>&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/seq_s.php">Sequence Search</a></b>&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* <b><a href="https://lms.snu.edu.in/corecg/pubmed_id_s.php">Pubmed Search</a></b> */}
              <br />
              <br />        <div class="break"></div>
            </div>
          </div>
          {/* <!-- end featured --> */}
          {/* <!-- begin recent posts --> */}
          <div class="recent">
            {/* <!-- begin post --> */}
            <div class="o post">
              <h1><a href="#">Plant Environment Stress Gene Database</a> (Version 1.0)</h1>
              <p align="justify">Welcome to <b>PlantESdb</b>!. PlantESdb is a data repository of ESR (Environmental stress
                responsive) genes and their transcriptional and post-transcriptional regulatory networks from
                published literatures and genomic databases with an effective knowledge based information
                retrieval system. The main aim of the database is to help the plant science research and maize
                community by providing a platform where all the detailed information of genes involved in
                stress is available. The information in database is stored in such a manner that user can easily
                find whether a gene or protein associated with stress or not. </p>
              <p class="readmore">[ <a href="/about">read more</a> ]</p>



            </div>
            {/* <!-- end post --> */}
          </div>
          {/* <!-- end recent posts --> */}
        </div>

        <Sidebar />

      </div>





    )
  }
}

export default App;
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
//   );
// }

// export default App;
