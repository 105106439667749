import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
// import $ from "min-jquery";
var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

// var copyspeed = marqueespeed
// var pausespeed = (pauseit == 0) ? copyspeed : 0
// var actualheight = ''
// var cross_marquee = { style: { top: '8px' } }
// // var cross_marquee = ''
// var marqueeheight = '3'
// function scrollmarquee() {
//   if (parseInt(cross_marquee.style.top) > (actualheight * (-1) + 8)) //if scroller hasn't reached the end of its height
//     cross_marquee.style.top = parseInt(cross_marquee.style.top) - copyspeed + "px" //move scroller upwards
//   else //else, reset to original position
//     cross_marquee.style.top = parseInt(marqueeheight) + 8 + "px"
// }

// function initializemarquee() {
//   cross_marquee = document.getElementById("vmarquee")
//   cross_marquee.style.top = 0
//   marqueeheight = document.getElementById("marqueecontainer").offsetHeight
//   actualheight = cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
//   if (window.opera || navigator.userAgent.indexOf("Netscape/7") != -1) { //if Opera or Netscape 7x, add scrollbars to scroll and exit
//     cross_marquee.style.height = marqueeheight + "px"
//     cross_marquee.style.overflow = "scroll"
//     return
//   }
//   setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
//   window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
//   window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
//   window.onload = initializemarquee


// function checkInput() {
//   var len = document.getElementById('search-text').value.length;
//   if (len < 1) {
//     alert('Please enter a search value');
//     return false;
//   }
// }



// on click of one of tabs
function displayPage() {
    var current = this.parentNode.getAttribute("data-current");
    //remove class of activetabheader and hide old contents
    document.getElementById("tabHeader_" + current).removeAttribute("class");
    document.getElementById("tabpage_" + current).style.display = "none";

    var ident = this.id.split("_")[1];
    //add class of activetabheader to new active tab and show contents
    this.setAttribute("class", "tabActiveHeader");
    document.getElementById("tabpage_" + ident).style.display = "block";
    this.parentNode.setAttribute("data-current", ident);
}


class SearchResult extends Component {
    state = {
        viewCompleted: false,
        activeItem: {
            title: "",
            description: "",
            completed: false,


        },
        data: [],
        type: 'Maize',
        generalinfo: {},
        mrnadata: {},
        mrnaseq: {},
        evidence: [],
        todoList: [],
        arabidopsis: [],
        genedata: [],
        refseqdata: {},
        cdsdata: {},
        protein: { 'uniprotdata': [], 'protein': '', 'status': 'Success' },
        cdna: 'No',
        content: '',
        domain: [],
        ontology: [],
        species: 'Maize',
        isLoaded: false
    };

    async componentDidMount() {
        try {
            // const res = await fetch('http://13.234.5.219:8000/api/todos/');
            // const todoList = await res.json();
            // this.setState({
            //   todoList
            // });
            // var filter_data = { 'status': 'search', 'type': this.state.type, 'content': this.state.content };
            // axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
            //   console.log('Data', resp)

            //   this.setState({ elements: resp.data.data, properties: resp.data.properties })
            // });
        } catch (e) {
            console.log(e);
        }
        // window.addEventListener('load', this.handleLoad);

    }

    // componentWillUnmount() {
    //   window.removeEventListener('load', this.handleLoad)
    // }



    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };


    handleChange = (event) => {
        // console.log("Event", event.target.value)

        this.setState({ type: event.target.value });
    }
    handleChange2 = (event) => { this.setState({ content: event.target.value }); }
    // handleChange3 = (event) => { this.setState({ gene: event.target.value }); }

    getArticlesFromApi = async () => {
        try {
            let response = await fetch(
                'https://examples.com/data.json'
            );
            let json = await response.json();
            return json.movies;
        } catch (error) {
            console.error(error);
        }
    };

    handleSubmit = (event) => {
        alert('A name was submitted: ' + this.state.species);
        event.preventDefault();

        try {

            var filter_data = { 'status': 'pubmed_search', 'type': this.state.type, 'pubmedid': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
                console.log('Data', resp)

                if (this.state.type == "Maize") {
                    this.setState({ evidence: resp.data.data })

                }
                else {
                    this.setState({ arabidopsis: resp.data.data })
                }


            });

            // fetch("http://13.234.5.219:8000/api/esanjeevni", {

            //     method: "POST", // *Type of request GET, POST, PUT, DELETE

            //     // mode: "cors", // Type of mode of the request

            //     // cache: "no-cache", // options like default, no-cache, reload, force-cache

            //     credentials: "same-origin", // options like include, *same-origin, omit

            //     headers: {

            //         "Content-Type": "application/json" // request content type
            //         // 'Accept': 'application/json',
            //         // 'X-Requested-With': 'XMLHttpRequest', //Necessary to work with request.is_ajax()


            //     },
            //     body: JSON.stringify(filter_data),

            //     // redirect: "follow", // manual, *follow, error

            //     // referrerPolicy: "no-referrer", // no-referrer, *client

            //     // body: JSON.stringify(data) // Attach body with the request

            // })
            //     .then(res => res.json())
            //     .then(
            //         (result) => {
            //             this.setState({
            //                 isLoaded: true,
            //                 arabidopsis: result.data
            //             });
            //             // console.log('temp', result)
            //         },
            //         // Note: it's important to handle errors here
            //         // instead of a catch() block so that we don't swallow
            //         // exceptions from actual bugs in components.
            //         (error) => {
            //             this.setState({
            //                 isLoaded: true,
            //                 error
            //             });
            //         }
            //     )



        } catch (e) {
            console.log(e);
        }


    }

    createItem = () => {
        const item = { title: "", description: "", completed: false };
        this.setState({ activeItem: item, modal: !this.state.modal });
    };

    displayCompleted = status => {
        if (status) {
            return this.setState({ viewCompleted: true });
        }
        return this.setState({ viewCompleted: false });
    };
    renderTabList = () => {
        return (
            <div className="my-5 tab-list">
                <button
                    onClick={() => this.displayCompleted(true)}
                    className={this.state.viewCompleted ? "active" : ""}
                >
                    Complete
                </button>
                <button
                    onClick={() => this.displayCompleted(false)}
                    className={this.state.viewCompleted ? "" : "active"}
                >
                    Incomplete
                </button>
            </div>
        );
    };

    renderItems = () => {
        const { viewCompleted } = this.state;
        const newItems = this.state.todoList.filter(
            item => item.completed === viewCompleted
        );
        return newItems.map(item => (
            <li
                key={item.id}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
                <span
                    className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
                        }`}
                    title={item.description}
                >
                    {item.title}
                </span>
            </li>
        ));
    };
    handlemaize = () => {
        this.setState({ content: '33371388', type: 'Maize' })

    }

    handlearabi = () => {
        this.setState({ content: '19321711', type: 'Arabidopsis' })
    }


    render() {
        var chunkedPartsArray = this.state.evidence;
        var arabidopsischunkedPartsArray = this.state.arabidopsis;

        return (
            <div id="wrapper">

                <div id="content">
                    <div id="featured">
                        <div class="post">
                            <h1><a href="#">Pubmed Search</a></h1>
                            <br /><br />
                            <form onSubmit={this.handleSubmit}>

                                <select id="search-sel" name="selSearchType" value={this.state.type} onChange={this.handleChange} style={{ height: '30px', width: '137px' }}>
                                    {/* <option value="Gene_Symbol" selected="selected">Species</option> */}
                                    <option value="Maize">Maize</option>
                                    <option value="Arabidopsis">Arabidopsis</option>
                                </select> &nbsp; &nbsp; &nbsp;
                                <input type="text" id="search-text" value={this.state.content} name="searchInput" onChange={this.handleChange2} style={{ height: '30px', width: '200px' }} /> &nbsp; &nbsp; &nbsp;
                                <input type="submit" value="Submit" style={{ height: '30px', width: '137px' }} />

                            </form><br />
                            <p> <h3> Example: <br />( Maize Pubmed ID-<a onClick={this.handlemaize}> 33371388</a>    Arabidopsis Pubmed ID-<a onClick={this.handlearabi}> 19321711</a>)</h3>   <br /> </p>

                            <br />
                            <br />

                            <div class="break"></div>


                        </div>


                    </div>

                    <br />
                    {isEmpty(this.state.evidence) ? "" : <div>
                        {this.state.type == 'Maize' ?
                            <div id="content" style={{ background: 'white', width: '100%' }}>


                                <h2>Search results:</h2><br />
                                <h3><u>General Information</u> (Click on respective Gene ID for more information) </h3><br />
                                <div class="bordered">

                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>  Pubmed ID </th>
                                                <th>  V5 Model ID </th>
                                                <th>  V4 Model ID </th>
                                                <th>  V3 Model ID </th>
                                                <th>  Year</th>
                                                <th>  Gene Symbol</th>
                                                <th>  Full Name</th>
                                                <th>  Stress</th>
                                                <th>  Regulation</th>
                                                <th>  Technique Used</th>
                                            </tr>


                                            {chunkedPartsArray.map((chunk, ind) => {
                                                console.log('row', chunk);
                                                return (<tr>
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'pubmedid') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td><a href={"https://www.ncbi.nlm.nih.gov/pubmed/?term=" + value} target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'v5_gene_model_id') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'v4_gene_model_id') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'v3genemodelid') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'year') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'gene_symbol') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'full_name') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'stress') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'regulation') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'technique_used') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>
                                                                }
                                                            })


                                                    }
                                                </tr>)
                                            })
                                            }



                                        </tbody>
                                    </table>

                                </div><br />

                            </div>
                            : ""}
                    </div>
                    }


                    {isEmpty(this.state.arabidopsis) ? "" : <div>
                        {this.state.type == 'Arabidopsis' ?
                            <div id="content" style={{ background: 'white', width: '100%' }}>


                                <h2>Search results:</h2><br />
                                <h3><u>General Information</u> (Click on respective Gene ID for more information) </h3><br />
                                <div class="bordered">

                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>  Pubmed ID </th>

                                                <th>  Year</th>
                                                <th>  Gene ID</th>

                                                <th>  Stress</th>
                                                <th>  Regulation</th>
                                                <th>  Technique Used</th>
                                            </tr>


                                            {arabidopsischunkedPartsArray.map((chunk, ind) => {
                                                console.log('row', chunk);
                                                return (<tr>
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'pubmedid') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td><a href={"https://www.ncbi.nlm.nih.gov/pubmed/?term=" + value} target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }





                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'geneid') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                                                }
                                                            })


                                                    }

                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'year') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }




                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'stress') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'regulation') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>

                                                                }
                                                            })


                                                    }
                                                    {
                                                        Object.entries(chunk)
                                                            .map(([key, value]) => {
                                                                if (key == 'technique_used') {
                                                                    // let t = value
                                                                    // return {value}

                                                                    return <td>{value}</td>
                                                                }
                                                            })


                                                    }
                                                </tr>)
                                            })
                                            }



                                        </tbody>
                                    </table>

                                </div><br />

                            </div>
                            : ""}
                    </div>
                    }


                </div >


                <Sidebar />

            </div >


        )
    }
}

export default SearchResult;
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
//   );
// }

// export default App;
