import React from "react";
import { Link, withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    <div id="wrapper" class="wrapper">
      {/* {this.props.children} */}

      <div id="header">

        <ul class="pages">
          <li><Link to="/">
            Home
            {/* <span class="sr-only">(current)</span> */}
          </Link></li>

          <li>
            <Link to="/about">
              About
              {/* <span class="sr-only">(current)</span> */}
            </Link>
          </li>
          <li>
            <Link to="/about">
              Help
              {/* <span class="sr-only">(current)</span> */}
            </Link>
          </li>
          <li>
            <Link to="/faq">
              FAQ
              {/* <span class="sr-only">(current)</span> */}
            </Link>
          </li>
          <li>
            <Link to="/contact">
              Contact
              {/* <span class="sr-only">(current)</span> */}
            </Link>
          </li>
        </ul>

        <div class="break"></div>

        <table cellpadding="0" cellspacing="0" border="0" style={{ width: '112%' }}>
          <tr>
            <td>
              <img src={process.env.PUBLIC_URL + '/images/IARIlogo.png'} alt="" style={{ height: '145px' }} />

            </td>
            <td style={{ textAlign: 'center', paddingLeft: '9%' }}>
              <div class="logo" >
                {/* <h1><a href="">PlantESdb</a></h1>
                <p>Plant Environment Stress Gene Database</p> */}
                <img src={process.env.PUBLIC_URL + '/images/logo_plantesdb.png'} alt="" style={{ height: '150px' }} />


              </div>


            </td>
            <td>
              <img src={process.env.PUBLIC_URL + '/images/icarlogo.gif'} alt="" style={{ height: '145px' }} />

            </td>
          </tr>
        </table>
        <br />
        <br />


        {/* <div class="sponsor" style={{float:'left'}}> <a href="" target="_blank"><img src={process.env.PUBLIC_URL + '/images/IARIlogo.png'}   alt="" style={{height: '195px'}}/></a> </div>
  
    <div class="logo" style={{paddingLeft:'26%', textAlign:'center',marginTop:'52px'}}>
      <h1><a href="">PlantESdb</a></h1>
      <p>Plant Environment Stress Gene Database</p>
    </div>
 
    <div class="sponsor"> <a href="" target="_blank"><img src={process.env.PUBLIC_URL + '/images/icarlogo.gif'}   alt="" style={{height: '195px'}}/></a> </div>
  
  */}
      </div>

      <div class="break"></div>

      <ul class="categories" style={{ padding: '1px' }}>
        <span class="preload1"></span>
        <span class="preload2"></span>



        <ul id="nav" style={{ backgroundColor: 'rgb(50, 68, 173)' }}>
          {/* <li class="top"><Link to="/" id="privacy" class="top_link" ><span>Home</span></Link></li> */}
          <li class="top"><a href="/" id="privacy" class="top_link"><span>Home</span></a></li>
          <li class="top"><a href="#" id="products" class="top_link"><span class="down">Search</span></a>
            <ul class="sub">
              {/* <li><Link to="/searchresults" id="privacy" class="top_link" >Keyword Search</Link>
              
                 <ul>
						<li><a href="">Gene ID</a></li>
						<li><a href="">Gene Symbol</a></li>
						<li><a href="">Chromosome</a></li>
					</ul> 
              </li> */}
              <li class="top"><a href="/searchresults" id="privacy" ><span>Keyword Search</span></a></li>
              {/* <li class="mid"><a href="">Advanced Search</a></li> */}
              {/* <li><a href="">Sequence Search</a></li> */}
              <li class="top"><a href="/pubmedsearch" id="privacy" ><span>Pubmed Search</span></a></li>
              {/* <li><a href="">Pubmed Search</a></li> */}
            </ul>
          </li>
          <li class="top"><a href="#" id="services" class="top_link"><span class="down">Browse</span></a>
            <ul class="sub">
              <li><a href="">Arabidopsis</a></li>
              <li><a href="">Maize</a></li>
              {/* <li><a href="">Gene Information</a></li>
<li><a href="#" class="fly">Gene Ontology</a>
				<ul>
					<li><a href="">Molecular Class</a></li>
					<li><a href="">Molecular Function</a></li>
					<li><a href="">Biological Process</a></li>
				</ul></li>
<li><a href="">Mechanism</a></li>
		
			<li><a href="">Pathways</a></li>
				<li><a href="">Stage</a></li> */}

            </ul>
          </li>
          <li class="top"><a href="#" id="contacts" class="top_link"><span class="down">Tools</span></a>
            <ul class="sub">
              {/* <li><a href="https://blast.ncbi.nlm.nih.gov/Blast.cgi?PROGRAM=blastx&PAGE_TYPE=BlastSearch&LINK_LOC=blasthome">Blast</a></li> */}
              {/* <li><a href="" target="_blank">GBrowse</a></li> */}
              <li><Link to="/blast" class="fly" >Blast</Link></li>
              <li><Link to="/gbrowser" class="fly" >GBrowse</Link></li>
              <li><Link to="/cytoscape" class="fly" >Regulatory Net(Cytoscape)</Link></li>
            </ul>
          </li>
          {/* <li class="top"><Link to="/About" class="top_link"><span>Database Statistics</span></Link></li> */}
          <li class="top"><a href="/statistics" id="privacy" class="top_link"><span>Database Statistics</span></a></li>
          <li class="top"><a href="/submitdata" id="privacy" class="top_link"><span>Submit Data</span></a></li>
          <li class="top"><a href="" id="privacy" class="top_link"><span>Acknowledgement</span></a></li>
          <li class="top"><a href="/teams" id="privacy" class="top_link"><span>Team</span></a></li>
        </ul></ul>


    </div>
    // <div cid="wrapper" style={{width:'1208px'}}>
    //   <div  id="header">
    //     {/* <div class="container"> */}
    //       {/* <Link class="navbar-brand" to="/">
    //         React Multi-Page Website
    //       </Link> */}
    //       {/* <div> */}
    //         <ul class="pages">
    //           <li
    //             class={`  ${
    //               props.location.pathname === "/" ? "active" : ""
    //             }`}
    //           >
    //             <Link  to="/">
    //               Home
    //               {/* <span class="sr-only">(current)</span> */}
    //             </Link>
    //           </li>
    //           <li
    //             class={`  ${
    //               props.location.pathname === "/about" ? "active" : ""
    //             }`}
    //           >
    //             <Link  to="/about">
    //               About
    //             </Link>
    //           </li>
    //           <li
    //             class={`  ${
    //               props.location.pathname === "/contact" ? "active" : ""
    //             }`}
    //           >
    //             <Link  to="/contact">
    //               Contact
    //             </Link>
    //           </li>
    //         </ul>
    //       {/* </div> */}
    //     {/* </div> */}
    //   </div>
    // </div>
  );
}

export default withRouter(Navigation);
