import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

// var copyspeed = marqueespeed
// var pausespeed = (pauseit == 0) ? copyspeed : 0
// var actualheight = ''
// var cross_marquee = { style: { top: '8px' } }
// // var cross_marquee = ''
// var marqueeheight = '3'
// function scrollmarquee() {
//   if (parseInt(cross_marquee.style.top) > (actualheight * (-1) + 8)) //if scroller hasn't reached the end of its height
//     cross_marquee.style.top = parseInt(cross_marquee.style.top) - copyspeed + "px" //move scroller upwards
//   else //else, reset to original position
//     cross_marquee.style.top = parseInt(marqueeheight) + 8 + "px"
// }

// function initializemarquee() {
//   cross_marquee = document.getElementById("vmarquee")
//   cross_marquee.style.top = 0
//   marqueeheight = document.getElementById("marqueecontainer").offsetHeight
//   actualheight = cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
//   if (window.opera || navigator.userAgent.indexOf("Netscape/7") != -1) { //if Opera or Netscape 7x, add scrollbars to scroll and exit
//     cross_marquee.style.height = marqueeheight + "px"
//     cross_marquee.style.overflow = "scroll"
//     return
//   }
//   setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
//   window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
//   window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
//   window.onload = initializemarquee


// function checkInput() {
//   var len = document.getElementById('search-text').value.length;
//   if (len < 1) {
//     alert('Please enter a search value');
//     return false;
//   }
// }



// on click of one of tabs
function displayPage() {
    var current = this.parentNode.getAttribute("data-current");
    //remove class of activetabheader and hide old contents
    document.getElementById("tabHeader_" + current).removeAttribute("class");
    document.getElementById("tabpage_" + current).style.display = "none";

    var ident = this.id.split("_")[1];
    //add class of activetabheader to new active tab and show contents
    this.setAttribute("class", "tabActiveHeader");
    document.getElementById("tabpage_" + ident).style.display = "block";
    this.parentNode.setAttribute("data-current", ident);
}


class SearchResult extends Component {
    state = {
        viewCompleted: false,
        activeItem: {
            title: "",
            description: "",
            completed: false,


        },
        data: [],
        type: 'Maize',
        generalinfo: { 'gene_symbol': '', 'geneid': '', 'name': '' },
        mrnadata: {},
        mrnaseq: {},
        evidence: [],
        todoList: [],
        genedata: [],
        refseqdata: {},
        cdsdata: {},
        protein: { 'uniprotdata': [], 'protein': '', 'status': 'Success' },
        cdna: 'No',
        content: '',
        domain: [],
        ontology: [],
        species: 'Maize'
    };

    async componentDidMount() {
        try {
            // const res = await fetch('http://13.234.5.219:8000/api/todos/');
            // const todoList = await res.json();
            // this.setState({
            //   todoList
            // });
            // var filter_data = { 'status': 'search', 'type': this.state.type, 'content': this.state.content };
            // axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
            //   console.log('Data', resp)

            //   this.setState({ elements: resp.data.data, properties: resp.data.properties })
            // });
        } catch (e) {
            console.log(e);
        }
        // window.addEventListener('load', this.handleLoad);

    }


    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };


    handleChange = (event) => {
        // console.log("Event", event.target.value)

        this.setState({ type: event.target.value });
    }
    handleChange2 = (event) => { this.setState({ content: event.target.value }); }
    // handleChange3 = (event) => { this.setState({ gene: event.target.value }); }

    getArticlesFromApi = async () => {
        try {
            let response = await fetch(
                'https://examples.com/data.json'
            );
            let json = await response.json();
            return json.movies;
        } catch (error) {
            console.error(error);
        }
    };

    handleSubmit = (event) => {
        alert('A name was submitted: ' + this.state.species);
        event.preventDefault();

        try {

            var filter_data = { 'status': 'search', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
                // console.log('Data', resp)

                this.setState({ data: resp.data.data, evidence: resp.data.evidence })
            });


        } catch (e) {
            console.log(e);
        }

        try {

            var filter_data = { 'status': 'search_gene', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ genedata: resp.data.genedata })
                console.log('Data2', this.state.genedata)
            });


        } catch (e) {
            console.log(e);
        }

        try {

            var filter_data = { 'status': 'search_cds', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ cdsdata: resp.data.cdsdata })
                // console.log('Data2', this.state.genedata)
            });


        } catch (e) {
            console.log(e);
        }

        try {

            var filter_data = { 'status': 'search_protein', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ protein: resp.data })
                // console.log('Data2', this.state.genedata)
            });


        } catch (e) {
            console.log(e);
        }



        try {

            var filter_data = { 'status': 'search_mrna', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ refseqdata: resp.data.refseqdata, cdna: resp.data.cdna })
                // console.log('Data2', this.state.cdna)
            });


        } catch (e) {
            console.log(e);
        }


        try {

            var filter_data = { 'status': 'search_domain', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ domain: resp.data.domain })
                // console.log('Data2', this.state.cdna)
            });


        } catch (e) {
            console.log(e);
        }

        try {

            var filter_data = { 'status': 'search_ontology', 'type': this.state.type, 'content': this.state.content };
            axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


                this.setState({ ontology: resp.data.ontology })
                // console.log('Data2', this.state.cdna)
            });


        } catch (e) {
            console.log(e);
        }
    }

    createItem = () => {
        const item = { title: "", description: "", completed: false };
        this.setState({ activeItem: item, modal: !this.state.modal });
    };

    displayCompleted = status => {
        if (status) {
            return this.setState({ viewCompleted: true });
        }
        return this.setState({ viewCompleted: false });
    };
    renderTabList = () => {
        return (
            <div className="my-5 tab-list">
                <button
                    onClick={() => this.displayCompleted(true)}
                    className={this.state.viewCompleted ? "active" : ""}
                >
                    Complete
                </button>
                <button
                    onClick={() => this.displayCompleted(false)}
                    className={this.state.viewCompleted ? "" : "active"}
                >
                    Incomplete
                </button>
            </div>
        );
    };

    renderItems = () => {
        const { viewCompleted } = this.state;
        const newItems = this.state.todoList.filter(
            item => item.completed === viewCompleted
        );
        return newItems.map(item => (
            <li
                key={item.id}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
                <span
                    className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
                        }`}
                    title={item.description}
                >
                    {item.title}
                </span>
            </li>
        ));
    };

    render() {
        var chunkedPartsArray = this.state.evidence;
        var gene_data = this.state.genedata;
        var protein_data = this.state.protein.uniprotdata;
        var domain_data = this.state.domain;
        var ontology_data = this.state.ontology;
        return (
            <div id="wrapper">

                <div id="content">
                    <div class="recent">
                        {/* <!-- begin post --> */}
                        <div class="single">
                            {/* <h2>Statistics</h2><br /><br /> */}
                            <div class="box">
                                <h2>Maize Regulatory Network Statistics</h2>
                                <ul class="popular">
                                    <table style={{ border: '1px black', width: '100%', borderCollapse: 'collapse' }} border="1"><colgroup><col width="50%" />
                                    </colgroup>
                                        <thead>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>Relation</b></td>
                                                <td style={{ padding: '3px' }}><center>Count</center></td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>miRNA-DEG</b></td>
                                                <td style={{ padding: '3px' }}><center>81060</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TargetMimic-miRNA</b></td>
                                                <td style={{ padding: '3px' }}><center>265</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-DEG</b></td>
                                                <td style={{ padding: '3px' }}><center>96685</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-miRNA</b></td>
                                                <td style={{ padding: '3px' }}><center>1073</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-TF</b></td>
                                                <td style={{ padding: '3px' }}><center>2209</center></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </ul>
                            </div>

                            <div class="box">
                                <h2>Arabidopsis Regulatory Network Statistics</h2>
                                <ul class="popular">
                                    <table style={{ border: '1px black', width: '100%', borderCollapse: 'collapse' }} border="1"><colgroup><col width="50%" />
                                    </colgroup>
                                        <thead>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>miRNA-DEG</b></td>
                                                <td style={{ padding: '3px' }}><center>137292</center></td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TargetMimic-miRNA</b></td>
                                                <td style={{ padding: '3px' }}><center>339</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-DEG</b></td>
                                                <td style={{ padding: '3px' }}><center>677063</center></td>

                                            </tr>
                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-miRNA</b></td>
                                                <td style={{ padding: '3px' }}><center>53204</center></td>

                                            </tr>

                                            <tr>
                                                <td style={{ padding: '3px' }}><b>TF-TF</b></td>
                                                <td style={{ padding: '3px' }}><center>20093</center></td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </ul>
                            </div>

                        </div>
                        {/* <!-- end post --> */}
                        <div class="single">

                            <br /><b><h2>1. KEGG Pathways</h2></b><br /><br />
                            <center><img src={process.env.PUBLIC_URL + '/images/KEGG_pathways.jpg'} alt="some_text" style={{ width: '100%' }} /><br /></center>
                            <br /><b><h2>2. Protein domains</h2></b><br /><br />
                            <center><img src={process.env.PUBLIC_URL + '/images/ProteinDomains.jpg'} alt="some_text" style={{ width: '100%' }} /><br /></center>
                            <br />

                        </div>
                    </div>


                </div >

                <Sidebar />

            </div >


        )
    }
}

export default SearchResult;
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
//   );
// }

// export default App;
