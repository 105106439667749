import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

// var copyspeed = marqueespeed
// var pausespeed = (pauseit == 0) ? copyspeed : 0
// var actualheight = ''
// var cross_marquee = { style: { top: '8px' } }
// // var cross_marquee = ''
// var marqueeheight = '3'
// function scrollmarquee() {
//   if (parseInt(cross_marquee.style.top) > (actualheight * (-1) + 8)) //if scroller hasn't reached the end of its height
//     cross_marquee.style.top = parseInt(cross_marquee.style.top) - copyspeed + "px" //move scroller upwards
//   else //else, reset to original position
//     cross_marquee.style.top = parseInt(marqueeheight) + 8 + "px"
// }

// function initializemarquee() {
//   cross_marquee = document.getElementById("vmarquee")
//   cross_marquee.style.top = 0
//   marqueeheight = document.getElementById("marqueecontainer").offsetHeight
//   actualheight = cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
//   if (window.opera || navigator.userAgent.indexOf("Netscape/7") != -1) { //if Opera or Netscape 7x, add scrollbars to scroll and exit
//     cross_marquee.style.height = marqueeheight + "px"
//     cross_marquee.style.overflow = "scroll"
//     return
//   }
//   setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
//   window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
//   window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
//   window.onload = initializemarquee


// function checkInput() {
//   var len = document.getElementById('search-text').value.length;
//   if (len < 1) {
//     alert('Please enter a search value');
//     return false;
//   }
// }

var search = {
  "data": {
    "geneid": "AT1G01010",
    "entrenzid": "839580",
    "genename": "NAC001",
    "chr": "1",
    "start_": "3631.0",
    "end_": "5899.0",
    "strand": "+",
    "gene_model_description": "NAC domain containing protein 1;(source:Araport11)",
    "gene_model_type": "protein_coding",
    "primary_gene_symbol": "NAC DOMAIN CONTAINING PROTEIN 1 (NAC001)",
    "all_gene_symbols": "NAC DOMAIN CONTAINING PROTEIN 1 (ANAC001); (NTL10);NAC DOMAIN CONTAINING PROTEIN 1 (NAC001)"
  },
  "evidence": [
    {
      "pubmedid": "23024208",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "light",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "31851942",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "High light",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "24101922",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Hypoxia",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "21558544",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "osmotic",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "19906889",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "osmotic",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "16081496",
      "year": "2005",
      "geneid": "AT1G01010",
      "stress": "Oxygen ",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "16081496",
      "year": "2006",
      "geneid": "AT1G01010",
      "stress": "oxygen ",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22553952",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "phosphate",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "18665916",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Oxygen ",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25617718",
      "year": "2015",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "18650403",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Virus",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "26724867",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "white light and Blue light",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "17905899",
      "year": "2007",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "18684332",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Arsenic",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "20307264",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "Cold, Heat, Drought and Oxidative",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "18684332",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Arsenate",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24897929",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "21398568",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27446194",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "nitric oxide",
      "regulation": "Up",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "24462115",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "nitric oxide",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22355415",
      "year": "2012",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23220693",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28717360",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27212052",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "titanium",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27086702",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "Virus",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25656510",
      "year": "2015",
      "geneid": "AT1G01010",
      "stress": "UV",
      "regulation": "Up",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat/Drought/TuMV/Drought/Drought+Heat/TuMV+Heat",
      "regulation": "Down/Down/Up/Down/Down/Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "21900481",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "Nitrogen",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "21558544",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "mannitol(osmotic)",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22984573",
      "year": "2012",
      "geneid": "AT1G01010",
      "stress": "Fe",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24377444",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Dehydration",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "23509177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "High Light",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24148294",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25311197",
      "year": "2015",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Drought+Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22984573",
      "year": "2012",
      "geneid": "AT1G01010",
      "stress": "Iron",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25329884",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Hyaloperonospora arabidopsidis (Hpa)",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "22408074",
      "year": "2012",
      "geneid": "AT1G01010",
      "stress": "Soil drying ",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Virus+Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22737149",
      "year": "2012",
      "geneid": "AT1G01010",
      "stress": "Tobacco etch potyVirus",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "19794116",
      "year": "2009",
      "geneid": "AT1G01010",
      "stress": "Tumour",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23964284",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "n-butanol vs tart-butanol",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24599491",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "oligogalacturonides",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Virus+Drought+Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "18650403",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Virus",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "30076223",
      "year": "2018",
      "geneid": "AT1G01010",
      "stress": "Biotic",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "16844837",
      "year": "2006",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "21190570",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "Salt and CO2",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "14739338",
      "year": "2004",
      "geneid": "AT1G01010",
      "stress": "UV",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat/Drought/TuMV/Drought/Drought+Heat/TuMV+Heat",
      "regulation": "Up/Up/Up/Up/Up/Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27208254",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "Flooding",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "20089509",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "17217541",
      "year": "2007",
      "geneid": "AT1G01010",
      "stress": "Nitrogen",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24098335",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "pathogen",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "24897929",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "17038189",
      "year": "2006",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "20089509",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat/Drought/TuMV/TuMV+Drought/Drought+ Heat/TuMV+ Heat/TuMV+ Heat+ Drought",
      "regulation": "Down/Down/Up/Down/Down/Down/Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "22194941",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "Hypoxia",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "32442250",
      "year": "2020",
      "geneid": "AT1G01010",
      "stress": "Salt/Mannitol/Heat/Salt+Mannitol/SH/Mannitol+Heat/Salt+Mannitol+Heat",
      "regulation": "Down/Down/Down/Down/Down/Down/Down",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "21248074",
      "year": "2011",
      "geneid": "AT1G01010",
      "stress": "Phosphate",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "31385625",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "wound",
      "regulation": "Na",
      "technique_used": "microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Drought+Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "30886204",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "17172353",
      "year": "2006",
      "geneid": "AT1G01010",
      "stress": "Agrobacterium tumefaciens-induced tumors",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "26013835",
      "year": "2015",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "32582226",
      "year": "2020",
      "geneid": "AT1G01010",
      "stress": "Magnesium",
      "regulation": "Up",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "21190570",
      "year": "2010",
      "geneid": "AT1G01010",
      "stress": "Salt and combined Salt +elavated CO2",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "TuMV + Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27329974",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "Cold/herbivory",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28915789",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "TuMV + Drought+Heat",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "32849690",
      "year": "2020",
      "geneid": "AT1G01010",
      "stress": "ultravoilet",
      "regulation": "Up",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "23753177",
      "year": "2013",
      "geneid": "AT1G01010",
      "stress": "Heat/Drought/TuMV/TuMV+Drought/Drought+ Heat/TuMV+ Heat/TuMV+ Heat+ Drought",
      "regulation": "Up/Up/Up/Up/Up/Up/Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "31681394",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Cl",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "26884175",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "27162276",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28717360",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "ethanol",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28717360",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "ethanol under high salinity ",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25452759",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Myzus persicae",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25452759",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Pieris rapae systemic/Spodoptera exigua systemic/Brevicoryne brassicae/Myzus persicae/wound",
      "regulation": "Up/Up/Up/Up/Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "30904943",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Nitrogen",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28717360",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Salt/ethanol",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "25452759",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Pieris rapae/Spodoptera exigua/wound/Myzus persicae/Brevicoryne brassicae",
      "regulation": "Up/Up/Up/Up/Up",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28522613",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "ABA/Dehydration",
      "regulation": "Down/Up",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "25674096",
      "year": "2015",
      "geneid": "AT1G01010",
      "stress": "Sulfate",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28442596",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Down",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "29462153",
      "year": "2018",
      "geneid": "AT1G01010",
      "stress": "Bacteria",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "26881808",
      "year": "2016",
      "geneid": "AT1G01010",
      "stress": "CO2 + Mg",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "29462153",
      "year": "2018",
      "geneid": "AT1G01010",
      "stress": "Bacteria/Phosphate",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "30886204",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "29554117",
      "year": "2018",
      "geneid": "AT1G01010",
      "stress": "Enterobacter sp. SA187/Salt/Enterobacter sp. SA187+Salt",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "31594842",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "28132837",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Biotic",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "31594842",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "High Light",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "31594842",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "High Light + Heat",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "18684332",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Arsenic",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "�24803504",
      "year": "2014",
      "geneid": "AT1G01010",
      "stress": "Salt",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "15734908",
      "year": "2005",
      "geneid": "AT1G01010",
      "stress": "Anoxia",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "31721759",
      "year": "2019",
      "geneid": "AT1G01010",
      "stress": "Phosphorus",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "18684332",
      "year": "2008",
      "geneid": "AT1G01010",
      "stress": "Arsenate",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "17905899",
      "year": "2007",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Down",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "17905899",
      "year": "2007",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "16972869",
      "year": "2006",
      "geneid": "AT1G01010",
      "stress": "Drought",
      "regulation": "Na",
      "technique_used": "Microarray"
    },
    {
      "pubmedid": "28915789",
      "year": "2017",
      "geneid": "AT1G01010",
      "stress": "Cold",
      "regulation": "Na",
      "technique_used": "NGS"
    },
    {
      "pubmedid": "29628371",
      "year": "2018",
      "geneid": "AT1G01010",
      "stress": "Heat",
      "regulation": "Na",
      "technique_used": "NGS"
    }
  ],
  "status": "Success"
}

var search_cds = {
  "cdsdata": "> Len:1290\nAT1G01010.1 cds chromosome:TAIR10:1:3631:5899:1 gene:AT1G01010 gene_biotype:protein_coding transcript_biotype:protein_coding gene_symbol:NAC001 description:NAC domain-containing protein 1 [Source:UniProtKB/Swiss-Prot;Acc:Q0WV96]\nATGGAGGATCAAGTTGGGTTTGGGTTCCGTCCGAACGACGAGGAGCTCGTTGGTCACTATCTCCGTAACAAAATCGAAGGAAACACTAGCCGCGACGTTGAAGTAGCCATCAGCGAGGTCAACATCTGTAGCTACGATCCTTGGAACTTGCGCTTCCAGTCAAAGTACAAATCGAGAGATGCTATGTGGTACTTCTTCTCTCGTAGAGAAAACAACAAAGGGAATCGACAGAGCAGGACAACGGTTTCTGGTAAATGGAAGCTTACCGGAGAATCTGTTGAGGTCAAGGACCAGTGGGGATTTTGTAGTGAGGGCTTTCGTGGTAAGATTGGTCATAAAAGGGTTTTGGTGTTCCTCGATGGAAGATACCCTGACAAAACCAAATCTGATTGGGTTATCCACGAGTTCCACTACGACCTCTTACCAGAACATCAGAGGACATATGTCATCTGCAGACTTGAGTACAAGGGTGATGATGCGGACATTCTATCTGCTTATGCAATAGATCCCACTCCCGCTTTTGTCCCCAATATGACTAGTAGTGCAGGTTCTGTGGTCAACCAATCACGTCAACGAAATTCAGGATCTTACAACACTTACTCTGAGTATGATTCAGCAAATCATGGCCAGCAGTTTAATGAAAACTCTAACATTATGCAGCAGCAACCACTTCAAGGATCATTCAACCCTCTCCTTGAGTATGATTTTGCAAATCACGGCGGTCAGTGGCTGAGTGACTATATCGACCTGCAACAGCAAGTTCCTTACTTGGCACCTTATGAAAATGAGTCGGAGATGATTTGGAAGCATGTGATTGAAGAAAATTTTGAGTTTTTGGTAGATGAAAGGACATCTATGCAACAGCATTACAGTGATCACCGGCCCAAAAAACCTGTGTCTGGGGTTTTGCCTGATGATAGCAGTGATACTGAAACTGGATCAATGATTTTCGAAGACACTTCGAGCTCCACTGATAGTGTTGGTAGTTCAGATGAACCGGGCCATACTCGTATAGATGATATTCCATCATTGAACATTATTGAGCCTTTGCACAATTATAAGGCACAAGAGCAACCAAAGCAGCAGAGCAAAGAAAAGGTGATAAGTTCGCAGAAAAGCGAATGCGAGTGGAAAATGGCTGAAGACTCGATCAAGATACCTCCATCCACCAACACGGTGAAGCAGAGCTGGATTGTTTTGGAGAATGCACAGTGGAACTATCTCAAGAACATGATCATTGGTGTCTTGTTGTTCATCTCCGTCATTAGTTGGATCATTCTTGTTGGTTAA",
  "status": "Success"
}

var search_gene = {
  "genedata": [
    {
      "gene_stable_id": "AT1G01010",
      "transcript_stable_id": "AT1G01010.1",
      "protein_stable_id": "AT1G01010.1",
      "xref": "NM_099983.2"
    }
  ],
  "status": "Success"
}

var search_protein = {
  "uniprotdata": [
    {
      "gene_stable_id": "AT1G01010",
      "transcript_stable_id": "AT1G01010.1",
      "protein_stable_id": "AT1G01010.1",
      "xref": "Q0WV96",
      "db_name": "Uniprot/SWISSPROT"
    },
    {
      "gene_stable_id": "AT1G01010",
      "transcript_stable_id": "AT1G01010.1",
      "protein_stable_id": "AT1G01010.1",
      "xref": "A0A178WAE4",
      "db_name": "Uniprot/SPTREMBL"
    }
  ],
  "protein": "> Len:429\nAT1G01010.1 pep chromosome:TAIR10:1:3631:5899:1 gene:AT1G01010 transcript:AT1G01010.1 gene_biotype:protein_coding transcript_biotype:protein_coding gene_symbol:NAC001 description:NAC domain-containing protein 1 [Source:UniProtKB/Swiss-Prot;Acc:Q0WV96]\nMEDQVGFGFRPNDEELVGHYLRNKIEGNTSRDVEVAISEVNICSYDPWNLRFQSKYKSRDAMWYFFSRRENNKGNRQSRTTVSGKWKLTGESVEVKDQWGFCSEGFRGKIGHKRVLVFLDGRYPDKTKSDWVIHEFHYDLLPEHQRTYVICRLEYKGDDADILSAYAIDPTPAFVPNMTSSAGSVVNQSRQRNSGSYNTYSEYDSANHGQQFNENSNIMQQQPLQGSFNPLLEYDFANHGGQWLSDYIDLQQQVPYLAPYENESEMIWKHVIEENFEFLVDERTSMQQHYSDHRPKKPVSGVLPDDSSDTETGSMIFEDTSSSTDSVGSSDEPGHTRIDDIPSLNIIEPLHNYKAQEQPKQQSKEKVISSQKSECEWKMAEDSIKIPPSTNTVKQSWIVLENAQWNYLKNMIIGVLLFISVISWIILVG",
  "status": "Success"
}

var search_ontology = {
  "domain": [
    {
      "geneid": "At1g01010",
      "pfamid": "PF02365",
      "pfamname": "NAM",
      "start_": "3",
      "end_": "139",
      "interproid": "IPR003441",
      "interproname": "NAM"
    }
  ],
  "status": "Success"
}

var search_mrna = {
  "refseqdata": {
    "gene_stable_id": "AT1G01010",
    "transcript_stable_id": "AT1G01010.1",
    "protein_stable_id": "AT1G01010.1",
    "xref": "NM_099983.2"
  },
  "cdna": "> Len:1688\nAT1G01010.1 cdna chromosome:TAIR10:1:3631:5899:1 gene:AT1G01010 gene_biotype:protein_coding transcript_biotype:protein_coding gene_symbol:NAC001 description:NAC domain-containing protein 1 [Source:UniProtKB/Swiss-Prot;Acc:Q0WV96]\nAAATTATTAGATATACCAAACCAGAGAAAACAAATACATAATCGGAGAAATACAGATTACAGAGAGCGAGAGAGATCGACGGCGAAGCTCTTTACCCGGAAACCATTGAAATCGGACGGTTTAGTGAAAATGGAGGATCAAGTTGGGTTTGGGTTCCGTCCGAACGACGAGGAGCTCGTTGGTCACTATCTCCGTAACAAAATCGAAGGAAACACTAGCCGCGACGTTGAAGTAGCCATCAGCGAGGTCAACATCTGTAGCTACGATCCTTGGAACTTGCGCTTCCAGTCAAAGTACAAATCGAGAGATGCTATGTGGTACTTCTTCTCTCGTAGAGAAAACAACAAAGGGAATCGACAGAGCAGGACAACGGTTTCTGGTAAATGGAAGCTTACCGGAGAATCTGTTGAGGTCAAGGACCAGTGGGGATTTTGTAGTGAGGGCTTTCGTGGTAAGATTGGTCATAAAAGGGTTTTGGTGTTCCTCGATGGAAGATACCCTGACAAAACCAAATCTGATTGGGTTATCCACGAGTTCCACTACGACCTCTTACCAGAACATCAGAGGACATATGTCATCTGCAGACTTGAGTACAAGGGTGATGATGCGGACATTCTATCTGCTTATGCAATAGATCCCACTCCCGCTTTTGTCCCCAATATGACTAGTAGTGCAGGTTCTGTGGTCAACCAATCACGTCAACGAAATTCAGGATCTTACAACACTTACTCTGAGTATGATTCAGCAAATCATGGCCAGCAGTTTAATGAAAACTCTAACATTATGCAGCAGCAACCACTTCAAGGATCATTCAACCCTCTCCTTGAGTATGATTTTGCAAATCACGGCGGTCAGTGGCTGAGTGACTATATCGACCTGCAACAGCAAGTTCCTTACTTGGCACCTTATGAAAATGAGTCGGAGATGATTTGGAAGCATGTGATTGAAGAAAATTTTGAGTTTTTGGTAGATGAAAGGACATCTATGCAACAGCATTACAGTGATCACCGGCCCAAAAAACCTGTGTCTGGGGTTTTGCCTGATGATAGCAGTGATACTGAAACTGGATCAATGATTTTCGAAGACACTTCGAGCTCCACTGATAGTGTTGGTAGTTCAGATGAACCGGGCCATACTCGTATAGATGATATTCCATCATTGAACATTATTGAGCCTTTGCACAATTATAAGGCACAAGAGCAACCAAAGCAGCAGAGCAAAGAAAAGGTGATAAGTTCGCAGAAAAGCGAATGCGAGTGGAAAATGGCTGAAGACTCGATCAAGATACCTCCATCCACCAACACGGTGAAGCAGAGCTGGATTGTTTTGGAGAATGCACAGTGGAACTATCTCAAGAACATGATCATTGGTGTCTTGTTGTTCATCTCCGTCATTAGTTGGATCATTCTTGTTGGTTAAGAGGTCAAATCGGATTCTTGCTCAAAATTTGTATTTCTTAGAATGTGTGTTTTTTTTTGTTTTTTTTTCTTTGCTCTGTTTTCTCGCTCCGGAAAAGTTTGAAGTTATATTTTATTAGTATGTAAAGAAGAGAAAAAGGGGGAAAGAAGAGAGAAGAAAAATGCAGAAAATCATATATATGAATTGGAAAAAAGTATATGTAATAATAATTAGTGCATCGTTTTGTGGTGTAGTTTATATAAATAAAGTGATATATAGTCTTGTATAAG",
  "status": "Success"
}

// on click of one of tabs
function displayPage() {
  var current = this.parentNode.getAttribute("data-current");
  //remove class of activetabheader and hide old contents
  document.getElementById("tabHeader_" + current).removeAttribute("class");
  document.getElementById("tabpage_" + current).style.display = "none";

  var ident = this.id.split("_")[1];
  //add class of activetabheader to new active tab and show contents
  this.setAttribute("class", "tabActiveHeader");
  document.getElementById("tabpage_" + ident).style.display = "block";
  this.parentNode.setAttribute("data-current", ident);
}


class SearchResult extends Component {
  state = {
    viewCompleted: false,
    activeItem: {
      title: "",
      description: "",
      completed: false,


    },
    data: [],
    type: 'Maize',
    generalinfo: {},
    mrnadata: {},
    mrnaseq: {},
    evidence: [],
    todoList: [],
    genedata: [],
    refseqdata: {},
    cdsdata: {},
    protein: { 'uniprotdata': [], 'protein': '', 'status': 'Success' },
    cdna: 'No',
    content: '',
    domain: [],
    ontology: [],
    species: 'Maize'
  };

  async componentDidMount() {
    try {
      // const res = await fetch('http://13.234.5.219:8000/api/todos/');
      // const todoList = await res.json();
      // this.setState({
      //   todoList
      // });
      // var filter_data = { 'status': 'search', 'type': this.state.type, 'content': this.state.content };
      // axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
      //   console.log('Data', resp)

      //   this.setState({ elements: resp.data.data, properties: resp.data.properties })
      // });
      this.setState({ type: "Maize" })

    } catch (e) {
      console.log(e);
    }
    // window.addEventListener('load', this.handleLoad);

  }

  // componentWillUnmount() {
  //   window.removeEventListener('load', this.handleLoad)
  // }

  handleLoad() {
    console.log("HandleLoad")
    // get tab container
    var container = document.getElementById("tabContainer");
    var tabcon = document.getElementById("tabscontent");
    //alert(tabcon.childNodes.item(1));
    // set current tab
    var navitem = document.getElementById("tabHeader_1");

    //store which tab we are on
    var ident = navitem.id.split("_")[1];
    //alert(ident);
    navitem.parentNode.setAttribute("data-current", ident);
    //set current tab with class of activetabheader
    navitem.setAttribute("class", "tabActiveHeader");

    //hide two tab contents we don't need
    var pages = tabcon.getElementsByTagName("div");
    for (var i = 1; i < pages.length; i++) {
      pages.item(i).style.display = "none";
    };

    //this adds click event to tabs
    var tabs = container.getElementsByTagName("li");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].onclick = displayPage;
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };


  handleChange = (event) => {
    // console.log("Event", event.target.value)

    this.setState({ type: event.target.value });
  }
  handleChange2 = (event) => { this.setState({ content: event.target.value }); }
  // handleChange3 = (event) => { this.setState({ gene: event.target.value }); }

  getArticlesFromApi = async () => {
    try {
      let response = await fetch(
        'https://examples.com/data.json'
      );
      let json = await response.json();
      return json.movies;
    } catch (error) {
      console.error(error);
    }
  };

  handleSubmit = (event) => {
    alert('Value submitted: ' + this.state.species + " " + this.state.content);
    event.preventDefault();

    this.setState({
      data: search.data,
      evidence: search.evidence,
      genedata: search_gene.genedata,
      cdsdata: search_cds.cdsdata,
      protein: search_protein,
      refseqdata: search_mrna.refseqdata,
      cdna: search_mrna.cdna,
      domain: search_ontology.domain,






    })

    try {

      var filter_data = { 'status': 'search', 'type': this.state.type, 'content': this.state.content };

      console.log('Fil 1', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {
        // console.log('Evidence', resp.data.evidence)

        // this.setState({ data: resp.data.data, evidence: resp.data.evidence })
      });


    } catch (e) {
      console.log(e);
    }

    try {

      var filter_data = { 'status': 'search_gene', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 2', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ genedata: resp.data.genedata })
        console.log('Genedata', this.state.genedata)
      });


    } catch (e) {
      console.log(e);
    }

    try {

      var filter_data = { 'status': 'search_cds', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 3', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ cdsdata: resp.data.cdsdata })
        // console.log('Data2', this.state.genedata)
      });


    } catch (e) {
      console.log(e);
    }

    try {

      var filter_data = { 'status': 'search_protein', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 4', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ protein: resp.data })
        // console.log('Data2', this.state.genedata)
      });


    } catch (e) {
      console.log(e);
    }



    try {

      var filter_data = { 'status': 'search_mrna', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 5', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ refseqdata: resp.data.refseqdata, cdna: resp.data.cdna })
        // console.log('Data2', this.state.cdna)
      });


    } catch (e) {
      console.log(e);
    }


    try {

      var filter_data = { 'status': 'search_domain', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 6', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ domain: resp.data.domain })
        // console.log('Data2', this.state.cdna)
      });


    } catch (e) {
      console.log(e);
    }

    try {

      var filter_data = { 'status': 'search_ontology', 'type': this.state.type, 'content': this.state.content };
      console.log('Fil 7', filter_data)
      axios.post('http://13.234.5.219:8000/api/esanjeevni', filter_data).then(resp => {


        this.setState({ ontology: resp.data.ontology })
        // console.log('Data2', this.state.cdna)
      });


    } catch (e) {
      console.log(e);
    }
  }

  createItem = () => {
    const item = { title: "", description: "", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };

  displayCompleted = status => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }
    return this.setState({ viewCompleted: false });
  };
  renderTabList = () => {
    return (
      <div className="my-5 tab-list">
        <button
          onClick={() => this.displayCompleted(true)}
          className={this.state.viewCompleted ? "active" : ""}
        >
          Complete
        </button>
        <button
          onClick={() => this.displayCompleted(false)}
          className={this.state.viewCompleted ? "" : "active"}
        >
          Incomplete
        </button>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      item => item.completed === viewCompleted
    );
    return newItems.map(item => (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-center"
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
            }`}
          title={item.description}
        >
          {item.title}
        </span>
      </li>
    ));
  };

  handlemaize = () => {
    this.setState({ content: 'Zm00001d000001', type: 'Maize' })

  }

  handlearabi = () => {
    this.setState({ content: 'AT1G01010', type: 'Arabidopsis' })
  }


  render() {
    var chunkedPartsArray = this.state.evidence;
    var gene_data = this.state.genedata;
    var protein_data = this.state.protein.uniprotdata;
    var domain_data = this.state.domain;
    var ontology_data = this.state.ontology;
    return (
      <div id="wrapper">

        <div id="content">
          <div id="featured">
            <div class="post">
              <h1><a href="#">Keyword Search</a></h1>
              <br /><br />
              <form onSubmit={this.handleSubmit}>

                <select id="search-sel" name="selSearchType" value={this.state.type} onChange={this.handleChange} style={{ height: '30px', width: '137px' }}>
                  {/* <option value="Gene_Symbol" selected="selected">Species</option> */}
                  <option value="Maize" >Maize</option>
                  <option value="Arabidopsis"   >Arabidopsis</option>
                </select> &nbsp; &nbsp; &nbsp;
                <input type="text" id="search-text" value={this.state.content} name="searchInput" onChange={this.handleChange2} style={{ height: '30px', width: '200px' }} /> &nbsp; &nbsp; &nbsp;
                <input type="submit" value="Submit" style={{ height: '30px', width: '137px' }} />

              </form><br />
              <p> <h3> Example: <br />( Maize V4 Gene ID-<a onClick={this.handlemaize}> Zm00001d000001</a>    Arabidopsis Gene ID-<a onClick={this.handlearabi}> AT1G01010</a>)</h3>   <br /> </p>


              <br />
              <br />        <div class="break"></div>
            </div>
          </div>
          <br />

          <h2>Search results:</h2><br />
          <h3><u>General Information</u></h3><br /><br />
          {this.state.type == 'Maize' ?
            <div class="bordered">
              <table>
                <thead>
                  <tr>
                    <th> Gene Model ID </th>
                    <th> Entrez Gene</th>
                    <th> Chromosome</th>
                    <th> Start</th>
                    <th> Stop</th>
                    <th> Ori</th>
                    <th> Description</th>

                  </tr>
                </thead>
                <thead>
                  <tr>
                    <td> <a href="#">{this.state.data.v4_gene_model_id} </a>
                    </td>
                    <td> <a href="#">{this.state.data.entrezgene}
                    </a>
                    </td><td>{this.state.data.chromosome} &nbsp; </td>
                    <td>{this.state.data.start}
                    </td>
                    <td>{this.state.data.stop}
                    </td>
                    <td>{this.state.data.ori}
                    </td><td>{this.state.data.description}</td>
                  </tr></thead></table>
            </div> :
            <div class="bordered">
              <table>
                <thead>
                  <tr>
                    <th> Gene ID </th>
                    <th> Entrez ID</th>
                    <th> Gene Name</th>
                    <th> Chromosome</th>
                    <th> Start</th>
                    <th> End</th>
                    <th> Strand</th>
                    <th> Gene Model Description</th>
                    <th> Gene Model Type</th>
                    <th> Primary Gene Symbol</th>
                    <th> All Gene Symbol</th>

                  </tr>
                </thead>
                <thead>
                  <tr>
                    <td> <a href="#">{this.state.data.geneid} </a>
                    </td>
                    <td> <a href="#">{this.state.data.entrenzid}
                    </a>
                    </td><td>{this.state.data.genename} &nbsp; </td>
                    <td>{this.state.data.chr}
                    </td>
                    <td>{this.state.data.start_}
                    </td>
                    <td>{this.state.data.end_}
                    </td>
                    <td>{this.state.data.strand}</td>
                    <td>{this.state.data.gene_model_description}</td>
                    <td>{this.state.data.gene_model_type}</td>
                    <td>{this.state.data.primary_gene_symbol}</td>
                    <td>{this.state.data.all_gene_symbol}</td>

                  </tr></thead></table>
            </div>}
          <br />
          <br /><br /><br /><br /><br /><br />




          {/* <!-- begin post --> */}
          <div class="o post">
            <div id="tabContainer" style={{ width: '100%' }}>
              <div id="tabs">
                <ul data-current="1">
                  <li id="tabHeader_1" class="tabActiveHeader">Evidence</li>
                  <li id="tabHeader_2">Gene</li>
                  <li id="tabHeader_3">mRNA</li>
                  <li id="tabHeader_4">CDS</li>
                  <li id="tabHeader_5">Protein</li>
                  <li id="tabHeader_6">PPi</li>
                  <li id="tabHeader_7">Pathway</li>

                  <li id="tabHeader_8">Domain</li>
                  <li id="tabHeader_9">Ontology</li>
                  <li id="tabHeader_10">External Links</li>
                  <li id="tabHeader_11">Orthologs</li>

                </ul>
              </div>
              <div class="bordered">
                <div id="tabscontent">
                  <div class="tabpage" id="tabpage_1" >
                    <br /> <br /><br /><h3>Evidence Information 1</h3><br />
                    {this.state.type == 'Maize' ?
                      <div style={{ display: "block" }}>
                        <div style={{ display: "block" }}>
                          <table>
                            <tbody>
                              <tr>
                                <th>  Pubmed ID </th>
                                <th>  V5 Model ID </th>
                                <th>  V4 Model ID </th>
                                <th>  V3 Model ID </th>
                                <th>  Year</th>
                                <th>  Gene Symbol</th>
                                <th>  Full Name</th>
                                <th>  Stress</th>
                                <th>  Regulation</th>
                                <th>  Technique Used</th>
                              </tr>


                              {chunkedPartsArray.map((chunk, ind) => {
                                console.log('row', chunk);
                                return (<tr>
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'pubmedid') {
                                          // let t = value
                                          // return {value}

                                          return <td><a href={"https://www.ncbi.nlm.nih.gov/pubmed/?term=" + value} target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                        }
                                      })


                                  }
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'v5_gene_model_id') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'v4_gene_model_id') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'v3genemodelid') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}&nbsp;&nbsp;&nbsp;</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'year') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'gene_symbol') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'full_name') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'stress') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'regulation') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'technique_used') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>
                                        }
                                      })


                                  }
                                </tr>)
                              })
                              }



                            </tbody>
                          </table>
                        </div>

                      </div> :
                      <div>
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                <th>  Pubmed ID </th>

                                <th>  Year</th>
                                <th>  Gene ID</th>

                                <th>  Stress</th>
                                <th>  Regulation</th>
                                <th>  Technique Used</th>
                              </tr>


                              {chunkedPartsArray.map((chunk, ind) => {
                                console.log('row', chunk);
                                return (<tr>
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'pubmedid') {
                                          // let t = value
                                          // return {value}

                                          return <td><a href={"https://www.ncbi.nlm.nih.gov/pubmed/?term=" + value} target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'year') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }

                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'geneid') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }


                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'stress') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'regulation') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>

                                        }
                                      })


                                  }
                                  {
                                    Object.entries(chunk)
                                      .map(([key, value]) => {
                                        if (key == 'technique_used') {
                                          // let t = value
                                          // return {value}

                                          return <td>{value}</td>
                                        }
                                      })


                                  }
                                </tr>)
                              })
                              }



                            </tbody>
                          </table>
                        </div>

                      </div>
                    }
                  </div>

                  <div class="tabpage" id="tabpage_2" style={{ display: 'none' }}  >
                    <br /> <br /><br /><h3>Gene Details</h3><br />
                    {this.state.type == 'Maize' ?

                      <table>
                        <tbody>
                          <tr>
                            <th>  Sequence Name </th>
                            <th>  Source </th>
                            <th>  Feature</th>
                            <th>  Start </th>
                            <th>  End </th>
                            <th>  Score </th>
                            <th>  Strand </th>
                            <th>  Frame </th>
                            <th>  Gene Source </th>
                            <th>  Gene Biotype </th>
                            <th>  Transcript ID </th>
                            <th>  Transcript Source </th>
                            <th>  Transcript Biotype </th>
                            <th>  Exon no </th>
                            <th>  Exon id </th>
                            <th>  Protein id </th>
                            <th>  Gene Name </th>
                          </tr>


                          {gene_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'seqname') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'source') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'feature') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'start') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'end_') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'score') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'strand') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'frame') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'gene_source') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_source') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_biotype') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'exon_number') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'exon_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'gene_name') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>
                                    }
                                  })


                              }
                            </tr>)
                          })
                          }



                        </tbody>
                      </table>
                      :

                      <table>
                        <tbody>
                          <tr>
                            <th>  Gene Stable ID </th>
                            <th>  Transcript Stable ID </th>
                            <th>  Protein Stable ID</th>
                            <th>  Xref </th>

                          </tr>


                          {gene_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'gene_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'xref') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }


                            </tr>)
                          })
                          }



                        </tbody>
                      </table>
                    }
                    <br />
                    <br />
                    {this.state.type == 'Maize' ?
                      <table><tbody><tr><th>Gene Type </th><td>protein coding </td></tr><tr><th> Description </th>
                        <td>
                          {this.state.data.description}

                          &nbsp;
                        </td>
                      </tr><tr><th> Start </th><td>{this.state.data.start} &nbsp; </td></tr><tr><th> End </th><td>{this.state.data.stop} &nbsp;</td></tr><tr><th> Ori </th><td>{this.state.data.ori} &nbsp;</td></tr><tr><th> Gene Sequence </th><td>


                        <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdna} />


                        <br /><br />&nbsp;&nbsp;&nbsp;<br /><br /></td></tr></tbody>
                      </table> :
                      <table><tbody>
                        <tr><th>Gene ID </th><td>protein coding </td></tr><tr><th> Description </th>
                          <td>
                            {this.state.data.gene_model_description}

                            &nbsp;
                          </td>
                        </tr>
                        <tr><th> Genename </th><td>{this.state.data.genename} &nbsp; </td></tr>
                        <tr><th> Chromosome </th><td>{this.state.data.chr} &nbsp; </td></tr>
                        <tr><th> Gene Model Type </th><td>{this.state.data.gene_model_type} &nbsp; </td></tr>
                        <tr><th> Primary Gene Symbol </th><td>{this.state.data.primary_gene_symbol} &nbsp; </td></tr>
                        <tr><th> All Gene Symbol </th><td>{this.state.data.all_gene_symbol} &nbsp; </td></tr>
                        <tr><th> Start </th><td>{this.state.data.start_} &nbsp; </td></tr>

                        <tr><th> End </th><td>{this.state.data.end_} &nbsp;</td></tr><tr><th> Strand </th><td>{this.state.data.ori} &nbsp;</td></tr><tr><th> Gene Sequence </th><td>


                          <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdna} />


                          <br /><br />&nbsp;&nbsp;&nbsp;<br /><br /></td></tr></tbody>
                      </table>}

                  </div>

                  <div class="tabpage" id="tabpage_3" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>mRNA Information</h3><br />
                    {this.state.type == 'Maize' ?
                      <table><tbody><tr><th> mRNA ID </th><td>{this.state.refseqdata.xref}</td></tr>
                        <tr><th> Gene Stable ID </th><td>{this.state.refseqdata.gene_stable_id} &nbsp; </td></tr>
                        <tr><th> Protein Stable ID </th><td>{this.state.refseqdata.protein_stable_id} &nbsp; </td></tr>
                        <tr><th> Transcript Stable ID </th><td>{this.state.refseqdata.transcript_stable_id} &nbsp; </td></tr>
                        <tr><th> mRNA Sequence </th><td>
                          <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdna} /><br /><br />&nbsp; &nbsp; &nbsp; <br /><br /></td></tr>
                        {/* <tr>
                          <th> Length </th><td>2025 &nbsp; </td>
                        </tr> */}

                      </tbody>
                      </table> :
                      <table><tbody><tr><th> mRNA ID </th><td>{this.state.refseqdata.xref}</td></tr>
                        <tr><th> Gene Stable ID </th><td>{this.state.refseqdata.gene_stable_id} &nbsp; </td></tr>
                        <tr><th> Protein Stable ID </th><td>{this.state.refseqdata.protein_stable_id} &nbsp; </td></tr>
                        <tr><th> Transcript Stable ID </th><td>{this.state.refseqdata.transcript_stable_id} &nbsp; </td></tr>
                        <tr><th> mRNA Sequence </th><td>
                          <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdna} /><br /><br />&nbsp; &nbsp; &nbsp; <br /><br /></td></tr>
                        {/* <tr>
                        <th> Length </th><td>2025 &nbsp; </td>
                      </tr> */}

                      </tbody>
                      </table>
                    }

                  </div>

                  <div class="tabpage" id="tabpage_4" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>CDS Information</h3><br />
                    {this.state.type == 'Maize' ?
                      <table><tbody>

                        <tr><th> CDS Sequence  </th><td>
                          <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdsdata} /><br /><br />&nbsp; &nbsp; &nbsp; <br /><br /></td></tr>
                        {/* <tr>
  <th> Length </th><td>2025 &nbsp; </td>
</tr> */}

                      </tbody>
                      </table> :
                      <table><tbody>

                        <tr><th> CDS Sequence  </th><td>
                          <textarea id="mrna_seq" cols="71" rows="10" value={this.state.cdsdata} /><br /><br />&nbsp; &nbsp; &nbsp; <br /><br /></td></tr>
                        {/* <tr>
 <th> Length </th><td>2025 &nbsp; </td>
</tr> */}

                      </tbody>
                      </table>
                    }
                  </div>

                  <div class="tabpage" id="tabpage_5" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>Protein Information</h3><br />
                    {this.state.type == 'Maize' ?
                      <table>
                        <tbody>
                          <tr>
                            <th>  Gene Stable ID </th>
                            <th>  Transcript Stable ID </th>
                            <th>  Protein Stable ID</th>
                            <th>  Uniprot Sptremblid </th>

                          </tr>


                          {protein_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'gene_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'uniprot_sptremblid') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }



                            </tr>)
                          })
                          }



                        </tbody>
                      </table>
                      :
                      <table>
                        <tbody>
                          <tr>
                            <th>  Gene Stable ID </th>
                            <th>  Transcript Stable ID </th>
                            <th>  Protein Stable ID</th>
                            <th>  Xref </th>

                          </tr>


                          {protein_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'gene_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_stable_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'xref') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }



                            </tr>)
                          })
                          }



                        </tbody>
                      </table>}
                    <br />
                    <br />
                    <table><tbody>
                      <tr><th> Protein Sequence  </th><td>


                        <textarea id="mrna_seq" cols="71" rows="10" value={this.state.protein.protein} />


                        <br /><br />&nbsp;&nbsp;&nbsp;<br /><br /></td></tr></tbody></table>

                  </div>

                  <div class="tabpage" id="tabpage_6" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>PPi Information</h3><br />
                    (Click to view network : Source STRING)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.type == 'Maize' ?
                      <b><a href={"http://string-db.org/newstring_cgi/show_network_section.pl?identifier=" + this.state.data.entrezgene} target="_blank">View</a>&nbsp;&nbsp;&nbsp;</b>

                      :
                      <b><a href={"http://string-db.org/newstring_cgi/show_network_section.pl?identifier=" + this.state.data.entrenzid} target="_blank">View</a>&nbsp;&nbsp;&nbsp;</b>
                    }
                    <br /><br />
                  </div>

                  <div class="tabpage" id="tabpage_7" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>Pathway Information</h3><br />

                    (Click to MaizeGDB Search with Pathway information : Source STRING)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.type == 'Maize' ?
                      <b><a href={"https://www.genome.jp/dbget-bin/www_bfind_sub?mode=bfind&amp;max_hit=1000&amp;dbkey=kegg&amp;keywords=" + this.state.data.entrezgene + ";site=ensemblthis"} target="_blank">View</a>&nbsp;&nbsp;&nbsp;</b>
                      :
                      <b><a href={"https://www.genome.jp/dbget-bin/www_bfind_sub?mode=bfind&amp;max_hit=1000&amp;dbkey=kegg&amp;keywords=" + this.state.data.entrenzid + ";site=ensemblthis"} target="_blank">View</a>&nbsp;&nbsp;&nbsp;</b>}
                    <br /><br />

                  </div>

                  <div class="tabpage" id="tabpage_8" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>Domain Information</h3><br />
                    {this.state.type == 'Maize' ?
                      <table>
                        <tbody>
                          <tr>
                            <th>  Transcript ID </th>
                            <th>  Protein Match Start </th>
                            <th>  Protein Match End</th>
                            <th>  Hmm ACC </th>
                            <th>  Hmm Match Start </th>
                            <th>  Hmm Match End </th>
                            <th>  Bit Score </th>
                            <th>  e-Value </th>
                            <th>  Hmm Name </th>

                          </tr>


                          {domain_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'transcript_id') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_match_start') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'protein_match_end') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'hmm_acc') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'hmm_match_start') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'hmm_match_end') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'bit_score') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'evalue') {
                                      // let t = value
                                      // return {value}

                                      return <td>{Number(value).toExponential(3)}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'hmm_name') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }



                            </tr>)
                          })
                          }



                        </tbody>
                      </table>
                      :
                      <table>
                        <tbody>
                          <tr>
                            <th>  Gene ID</th>
                            <th>  Protein Match Start </th>
                            <th>  Protein Match End</th>
                            <th>  Pfamid </th>
                            <th>  Pfamname </th>
                            <th>  Interproid </th>
                            <th>  Interproname </th>


                          </tr>


                          {domain_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'geneid') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'start_') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'end_') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'pfamid') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'pfamname') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'interproid') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'interproname') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }



                            </tr>)
                          })
                          }



                        </tbody>
                      </table>}
                    <br />

                  </div>
                  <div class="tabpage" id="tabpage_9" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>Ontology Information</h3><br />
                    {this.state.type == 'Maize' ?
                      <table>
                        <tbody>
                          <tr>
                            <th>  Go Accession </th>
                            <th>  Go Type </th>
                            <th>  Go Name</th>
                            <th>  Go EV Code </th>
                            <th>  Go DB XREF </th>


                          </tr>


                          {ontology_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_accession') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_type') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_name') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_ev_code') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_dbxref') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }




                            </tr>)
                          })
                          }



                        </tbody>
                      </table>
                      :
                      <table>
                        <tbody>
                          <tr>
                            <th>  Locus </th>
                            <th>  Go Term </th>
                            <th>  Go ID</th>
                            <th>  Category </th>
                            <th>  Go Slim_s </th>
                            <th>  Evidence Code </th>


                          </tr>


                          {ontology_data.map((chunk, ind) => {
                            // console.log('row', chunk);
                            return (<tr>
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'locus') {
                                      // let t = value
                                      // return {value}

                                      return <td><a href="#" target="_blank">{value}</a>&nbsp;&nbsp;&nbsp;</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_term') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_id') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'category') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }

                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'go_slim_s') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }
                              {
                                Object.entries(chunk)
                                  .map(([key, value]) => {
                                    if (key == 'evidence_code') {
                                      // let t = value
                                      // return {value}

                                      return <td>{value}</td>

                                    }
                                  })


                              }




                            </tr>)
                          })
                          }



                        </tbody>
                      </table>}
                    <br />

                  </div>

                  <div class="tabpage" id="tabpage_10" style={{ display: 'none' }} >
                    <br /> <br /><br /><h3>External Links Information</h3><br />

                    <br /> <h3>External Links</h3><br />
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {this.state.type == 'Maize' ?
                      <table>
                        <tbody>
                          <tr></tr>
                          <tr><th> MaizeGDB Search:</th><td><a href={"https://www.maizegdb.org/gene_center/gene/" + this.state.data.v4_gene_model_id} target="_blank">GO</a></td></tr>
                          <tr><th> EnsemblPlants Search:</th><td><a href={"https://plants.ensembl.org/Zea_mays/Search/Results?species=Zea_mays;idx=;q=" + this.state.data.v4_gene_model_id + ";site=ensemblthis"} target="_blank">GO</a></td></tr>
                          <tr><th> Keggs Search:</th><td><a href={"https://www.genome.jp/dbget-bin/www_bfind_sub?mode=bfind&amp;max_hit=1000&amp;dbkey=kegg&amp;keywords=" + this.state.data.entrezgene + ";site=ensemblthis"} target="_blank">GO</a></td></tr>
                          <tr><th> Expression Atlas:</th><td><a href={"https://www.ebi.ac.uk/gxa/experiments?experimentType=differential&kingdom=Plants"} target="_blank">GO</a></td></tr>




                        </tbody>
                      </table> :
                      <table>
                        <tbody>
                          <tr></tr>
                          <tr><th> MaizeGDB Search:</th><td><a href={"https://www.maizegdb.org/gene_center/gene/" + this.state.data.geneid} target="_blank">GO</a></td></tr>
                          <tr><th> EnsemblPlants Search:</th><td><a href={"https://plants.ensembl.org/Zea_mays/Search/Results?species=Zea_mays;idx=;q=" + this.state.data.geneid + ";site=ensemblthis"} target="_blank">GO</a></td></tr>
                          <tr><th> Keggs Search:</th><td><a href={"https://www.genome.jp/dbget-bin/www_bfind_sub?mode=bfind&amp;max_hit=1000&amp;dbkey=kegg&amp;keywords=" + this.state.data.entrezid + ";site=ensemblthis"} target="_blank">GO</a></td></tr>
                          <tr><th> Expression Atlas:</th><td><a href={"https://www.ebi.ac.uk/gxa/experiments?experimentType=differential&kingdom=Plants"} target="_blank">GO</a></td></tr>
                          <tr><th> TAIR Search API:</th><td><a href={"https://www.arabidopsis.org/servlets/TairObject?type=locus&name=" + this.state.data.geneid} target="_blank">GO</a></td></tr>
                          <tr><th> Arabidopsis eFP Browser :</th><td><a href={"http://bar.utoronto.ca/efp/cgi-bin/efpWeb.cgi?primaryGene=" + this.state.data.geneid + "&modeInput=Absolute"} target="_blank">GO</a></td></tr>
                          <tr><th> Gbrowse related files for Arabidopsis :</th><td><a href="https://www.arabidopsis.org/download/index-auto.jsp?dir=%2Fdownload_files%2FMaps%2Fgbrowse_data%2FTAIR10" target="_blank">GO</a></td></tr>




                        </tbody>
                      </table>
                    }

                    <br /><br />
                  </div>
                  <b>

                  </b>
                </div>
                <b>
                </b>
              </div>
              <b>




              </b></div><b>
              {/* <!-- end post --> */}
            </b></div><b>
            {/* <!-- end recent posts -->          */}
          </b>

        </div >

        <Sidebar />

      </div >


    )
  }
}

export default SearchResult;
// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
//   );
// }

// export default App;
