import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

var copyspeed = marqueespeed
var pausespeed = (pauseit == 0) ? copyspeed : 0
var actualheight = ''

// function scrollmarquee(){
// if (parseInt(cross_marquee.style.top)>(actualheight*(-1)+8)) //if scroller hasn't reached the end of its height
// cross_marquee.style.top=parseInt(cross_marquee.style.top)-copyspeed+"px" //move scroller upwards
// else //else, reset to original position
// cross_marquee.style.top=parseInt(marqueeheight)+8+"px"
// return cross_marquee
// }

// function initializemarquee(){
// cross_marquee=document.getElementById("vmarquee")
// cross_marquee.style.top=0
// marqueeheight=document.getElementById("marqueecontainer").offsetHeight
// actualheight=cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
// if (window.opera || navigator.userAgent.indexOf("Netscape/7")!=-1){ //if Opera or Netscape 7x, add scrollbars to scroll and exit
// cross_marquee.style.height=marqueeheight+"px"
// cross_marquee.style.overflow="scroll"
// return cross_marquee
// }
// setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
// window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
// window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
// window.onload=initializemarquee


function checkInput() {
    var len = document.getElementById('search-text').value.length;
    if (len < 1) {
        alert('Please enter a search value');
        return false;
    }
}



class FAQ extends Component {
    state = {
        viewCompleted: false,
        activeItem: {
            title: "",
            description: "",
            completed: false
        },
        todoList: []
    };

    async componentDidMount() {
        try {
            const res = await fetch('http://13.234.5.219:8000/api/todos/');
            const todoList = await res.json();
            this.setState({
                todoList
            });
        } catch (e) {
            console.log(e);
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    //Responsible for saving the task
    handleSubmit = item => {
        this.toggle();
        if (item.id) {
            axios
                .put(`http://13.234.5.219:8000/api/todos/${item.id}/`, item)
            return;
        }
        axios
            .post("http://13.234.5.219:8000/api/todos/", item)
    };

    createItem = () => {
        const item = { title: "", description: "", completed: false };
        this.setState({ activeItem: item, modal: !this.state.modal });
    };

    displayCompleted = status => {
        if (status) {
            return this.setState({ viewCompleted: true });
        }
        return this.setState({ viewCompleted: false });
    };
    renderTabList = () => {
        return (
            <div className="my-5 tab-list">
                <button
                    onClick={() => this.displayCompleted(true)}
                    className={this.state.viewCompleted ? "active" : ""}
                >
                    Complete
                </button>
                <button
                    onClick={() => this.displayCompleted(false)}
                    className={this.state.viewCompleted ? "" : "active"}
                >
                    Incomplete
                </button>
            </div>
        );
    };

    renderItems = () => {
        const { viewCompleted } = this.state;
        const newItems = this.state.todoList.filter(
            item => item.completed === viewCompleted
        );
        return newItems.map(item => (
            <li
                key={item.id}
                className="list-group-item d-flex justify-content-between align-items-center"
            >
                <span
                    className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
                        }`}
                    title={item.description}
                >
                    {item.title}
                </span>
            </li>
        ));
    };

    render() {
        return (




            <div id="wrapper" class="wrapper" >
                {/* {this.props.children} */}

                <div id="content">
                    {/* <!-- begin featured --> */}

                    {/* <!-- end featured --> */}
                    {/* <!-- begin recent posts --> */}
                    <div class="recent">
                        {/* <!-- begin post --> */}
                        <div class="post">
                            <h1>What is PlantESdb?</h1>
                            <p align="justify">PlantESdb (Plant Environmental Stress database) is a comprehensive, composite database
                                and inclusive collection of individual as well as combined environmental stresses responsive
                                genes (ESR genes) and their miRNA-transcription factor mediated regulatory networks in
                                Arabidopsis thaliana and Zea mays. PlantESdb consists of all the relevant ESR genes from
                                published literatures with an effective knowledge based information retrieval system.</p>




                        </div>

                        <div class="post">
                            <h1>Why PlantESdb was created?</h1>
                            <p align="justify">Climate change and anthropogenic activities driven stresses such as extreme temperatures,
                                water deficit, and ion imbalance along with biotic agents like pests and diseases are projected
                                to exacerbate and jeopardize global food security. Developing stress tolerant varieties with
                                better yield is first on priority list for fulfilling the future need of food and nutrition.
                                Comprehensive knowledge about the molecular mechanism of stress tolerance would be the
                                initial step towards enhancing the crop resilience to environmental stresses. PlantESdb will
                                serve researchers a system to browse through and extract the basic and advanced information
                                about the genes involved in various environmental stresses (both individual and combined
                                stresses). It is further expected that availability and use of PlantESdb will reduce the time and
                                effort of plant researchers to survey the literature on genes and their involvement in various
                                environmental stresses as a result can make further advances towards plant stress biology.</p>




                        </div>

                        <div class="post">
                            <h1>What is unique about PlantESdb?</h1>



                            <ul>
                                <li> <p align="justify">There are several features which makes PlantESdb unique, to the best of our
                                    knowledge is the first collection of genes associated with Environmental stresses,
                                    which has been manually curated and gathered through published literature.</p></li>
                                <li><p align="justify">The unique point of the database is the extensive literature survey, fine detail
                                    information, well networked with major resource databases.</p></li>
                                <li><p align="justify">A user can find if his gene of interest is differentially expressed in particular
                                    environmental stress or not.</p></li>
                                <li><p align="justify">PlantESdb facilitates and is helpful in providing information about microRNA and
                                    transcription factor regulated network for user defined list of genes and also provides
                                    basic network measures information.</p></li>

                            </ul>





                        </div>

                        <div class="post">
                            <h1>Does this database represent all genes associated with Environmental stresses?</h1>
                            <p align="justify">This knowledgebase is the result of first round manual curation and we are continuously
                                updating any new information we found. In future, we will add information about other plant
                                species as well.</p>




                        </div>

                        <div class="post">
                            <h1>To whom can I report a bug or discrepancy?</h1>
                            <p align="justify">Please refer to the &quot;Contact Us&quot; page.</p>




                        </div>

                        <div class="post">
                            <h1>Can I submit my gene in PlantESdb?</h1>
                            <p align="justify">Please refer to the &quot;Submission&quot; page.</p>




                        </div>

                        <div class="post">
                            <h1>Can I use it for sequence analysis tools?</h1>
                            <p align="justify">Definitely we are willing to make this single point source for your requirements related to
                                Environmental stress associated genes; we have included Blast search option.</p>




                        </div>


                        {/* <!-- end post --> */}
                    </div>
                    {/* <!-- end recent posts --> */}
                </div>

                <Sidebar />

            </div>

        )
    }
}

export default FAQ;
