import React, { Component } from "react"
// import Modal from "./components/Modal"; 
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
// import GenomeViewer from 'react-genome-viewer';
import Iframe from 'react-iframe';

// import Cytoscape from 'cytoscape';
// import COSEBilkent from 'cytoscape-cose-bilkent';
// // import React from 'react';
// import CytoscapeComponent from 'react-cytoscapejs';
// // import cola from 'cytoscape-cola';
// var cola = require('cytoscape-cola/cytoscape-cola.js');

import { createViewState, JBrowseLinearGenomeView } from '@jbrowse/react-linear-genome-view';


// import '@fontsource/roboto/files';

const assembly = {
  name: 'GRCh38',
  sequence: {
    type: 'ReferenceSequenceTrack',
    trackId: 'GRCh38-ReferenceSequenceTrack',
    adapter: {
      type: 'BgzipFastaAdapter',
      fastaLocation: {
        uri:
          'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz',
      },
      faiLocation: {
        uri:
          'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz.fai',
      },
      gziLocation: {
        uri:
          'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/fasta/GRCh38.fa.gz.gzi',
      },
    },
  },
  aliases: ['hg38'],
  refNameAliases: {
    adapter: {
      type: 'RefNameAliasAdapter',
      location: {
        uri:
          'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/hg38_aliases.txt',
      },
    },
  },
}

const tracks = [
  {
    type: 'FeatureTrack',
    trackId: 'ncbi_refseq_109_hg38',
    name: 'NCBI RefSeq (GFF3Tabix)',
    assemblyNames: ['GRCh38'],
    category: ['Annotation'],
    adapter: {
      type: 'Gff3TabixAdapter',
      gffGzLocation: {
        uri:
          'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/ncbi_refseq/GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff.gz',
      },
      index: {
        location: {
          uri:
            'https://s3.amazonaws.com/jbrowse.org/genomes/GRCh38/ncbi_refseq/GCA_000001405.15_GRCh38_full_analysis_set.refseq_annotation.sorted.gff.gz.tbi',
        },
      },
    },
  },
]

const defaultSession = {
  name: 'My session',
  view: {
    id: 'linearGenomeView',
    type: 'LinearGenomeView',
    tracks: [
      {
        type: 'ReferenceSequenceTrack',
        configuration: 'GRCh38-ReferenceSequenceTrack',
        displays: [
          {
            type: 'LinearReferenceSequenceDisplay',
            configuration:
              'GRCh38-ReferenceSequenceTrack-LinearReferenceSequenceDisplay',
          },
        ],
      },
      {
        type: 'FeatureTrack',
        configuration: 'ncbi_refseq_109_hg38',
        displays: [
          {
            type: 'LinearBasicDisplay',
            configuration: 'ncbi_refseq_109_hg38-LinearBasicDisplay',
          },
        ],
      },
    ],
  },
}

// Cytoscape.use(COSEBilkent);

// import fcose from 'cytoscape-fcose';

// Cytoscape.use( fcose );


// Cytoscape.use(cola);
// 
var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////

var copyspeed = marqueespeed
var pausespeed = (pauseit == 0) ? copyspeed : 0
var actualheight = ''

// function scrollmarquee(){
// if (parseInt(cross_marquee.style.top)>(actualheight*(-1)+8)) //if scroller hasn't reached the end of its height
// cross_marquee.style.top=parseInt(cross_marquee.style.top)-copyspeed+"px" //move scroller upwards
// else //else, reset to original position
// cross_marquee.style.top=parseInt(marqueeheight)+8+"px"
// return cross_marquee
// }

// function initializemarquee(){
// cross_marquee=document.getElementById("vmarquee")
// cross_marquee.style.top=0
// marqueeheight=document.getElementById("marqueecontainer").offsetHeight
// actualheight=cross_marquee.offsetHeight //height of marquee content (much of which is hidden from view)
// if (window.opera || navigator.userAgent.indexOf("Netscape/7")!=-1){ //if Opera or Netscape 7x, add scrollbars to scroll and exit
// cross_marquee.style.height=marqueeheight+"px"
// cross_marquee.style.overflow="scroll"
// return cross_marquee
// }
// setTimeout('lefttime=setInterval("scrollmarquee()",30)', delayb4scroll)
// }

// if (window.addEventListener)
// window.addEventListener("load", initializemarquee, false)
// else if (window.attachEvent)
// window.attachEvent("onload", initializemarquee)
// else if (document.getElementById)
// window.onload=initializemarquee


function checkInput() {
  var len = document.getElementById('search-text').value.length;
  if (len < 1) {
    alert('Please enter a search value');
    return false;
  }
}



class JBrowser extends Component {
  state = {
    viewCompleted: false,
    activeItem: {
      title: "",
      description: "",
      completed: false,
      elements: [
        { data: { id: 'one', label: 'Node 1' }, position: { x: 0, y: 0 } },
        { data: { id: 'two', label: 'Node 2' }, position: { x: 100, y: 0 } },
        { data: { source: 'one', target: 'two', label: 'Edge from Node1 to Node2' } }
      ]
    },
    todoList: [],

  };

  async componentDidMount() {
    try {
      const res = await fetch('http://13.234.5.219:8000/api/todos/');
      const todoList = await res.json();
      this.setState({
        todoList
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  //Responsible for saving the task
  handleSubmit = item => {
    this.toggle();
    if (item.id) {
      axios
        .put(`http://13.234.5.219:8000/api/todos/${item.id}/`, item)
      return;
    }
    axios
      .post("http://13.234.5.219:8000/api/todos/", item)
  };

  createItem = () => {
    const item = { title: "", description: "", completed: false };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };

  displayCompleted = status => {
    if (status) {
      return this.setState({ viewCompleted: true });
    }
    return this.setState({ viewCompleted: false });
  };
  renderTabList = () => {
    return (
      <div className="my-5 tab-list">
        <button
          onClick={() => this.displayCompleted(true)}
          className={this.state.viewCompleted ? "active" : ""}
        >
          Complete
        </button>
        <button
          onClick={() => this.displayCompleted(false)}
          className={this.state.viewCompleted ? "" : "active"}
        >
          Incomplete
        </button>
      </div>
    );
  };

  renderItems = () => {
    const { viewCompleted } = this.state;
    const newItems = this.state.todoList.filter(
      item => item.completed === viewCompleted
    );
    return newItems.map(item => (
      <li
        key={item.id}
        className="list-group-item d-flex justify-content-between align-items-center"
      >
        <span
          className={`todo-title mr-2 ${this.state.viewCompleted ? "completed-todo" : ""
            }`}
          title={item.description}
        >
          {item.title}
        </span>
      </li>
    ));
  };


  render() {

    // npm install biojs-vis-blasterjs
    const state = createViewState({
      assembly,
      tracks,
      defaultSession,

      // use 0-based coordinates for "location object" here
      location: { refName: 'ctgA', start: 10000, end: 20000 },
    })
    const layout = { name: 'cola' };

    return (
      <div id="wrapper" >
        {/* {this.props.children} */}

        {/* <div >
              
              <CytoscapeComponent elements={elements} layout={layout} style={ { width: '1000px', height: '1000px' } } /> 
            </div> */}


        {/* <JBrowseLinearGenomeView viewState={state} /> */}

        <Iframe url="http://13.234.5.219/cgi-bin/gbrowse/gbrowse/yeast_advanced/"
          width="100%"
          height="1000px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative" />


      </div>
    );

  }
}
// sudo make install
export default JBrowser;
