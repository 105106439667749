import React, { useEffect, useRef } from "react";
// import $ from 'jquery';
// import 'jquery.marquee';

var delayb4scroll = 2000 //Specify initial delay before marquee starts to scroll on page (2000=2 seconds)
var marqueespeed = 1 //Specify marquee scroll speed (larger is faster 1-10)
var pauseit = 1 //Pause marquee onMousever (0=no. 1=yes)?

////NO NEED TO EDIT BELOW THIS LINE////////////




function Sidebar(props) {
	// const el = useRef();

	// useEffect(function () {
	// const $el = $(el.current);

	// 	$el.marquee({
	// 		duration: 5000,
	// 		gap: 50,
	// 		delayBeforeStart: 2000,
	// 		direction: 'up'
	// 	});
	// });
	return (
		<div id="sidebar">
			{/* <!-- begin popular posts --> */}
			<div class="box">
				<h2>Highlights</h2>
				<ul class="popular">
					<div id="marqueecontainer" onmouseover="copyspeed=pausespeed" onmouseout="copyspeed=marqueespeed">
						<div id="vmarquee" style={{ position: 'absolute', width: '98%', top: "-1096px" }}>
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33371388" target="_blank">Comparative Transcriptome Analysis of Iron and Zinc Deficiency in Maize ( Zea mays L.)</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33582802" target="_blank">Transcription-associated metabolomic adjustments in maize occur during combined drought and
								cold stress</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=29558449" target="_blank">Long Non-Coding RNAs Responsive to Salt and Boron Stress in the Hyper-Arid Lluteño Maize from
								Atacama Desert</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26681689" target="_blank">Transcriptional regulatory networks in Arabidopsis thaliana during single and combined stresses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23753177" target="_blank">Simultaneous application of heat, drought, and virus to Arabidopsis plants reveals significant shifts in
								signaling networks</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=21190570" target="_blank">Individual vs. combinatorial effect of elevated CO2 conditions and salinity stress on Arabidopsis
								thaliana liquid cultures: comparing the early molecular response using time-series transcriptomic
								and metabolomic analyses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23447525" target="_blank">Transcriptome responses to combinations of stresses in Arabidopsis</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26881808" target="_blank">Physiological and Transcriptome Responses to Combinations of Elevated CO2 and Magnesium in
								Arabidopsis thaliana.</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=32442250" target="_blank">Molecular plant responses to combined abiotic stresses put a spotlight on unknown and abundant
								genes.</a>&nbsp;&nbsp;&nbsp;
							<br /><br />

						</div>
					</div>


					{/* 
					<div id="marqueecontainer" ref={el}>
						<div id="vmarquee" style={{ position: 'absolute', width: '98%', top: "-1096px" }}>
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33371388" target="_blank">Comparative Transcriptome Analysis of Iron and Zinc Deficiency in Maize ( Zea mays L.)</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33582802" target="_blank">Transcription-associated metabolomic adjustments in maize occur during combined drought and
								cold stress</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=29558449" target="_blank">Long Non-Coding RNAs Responsive to Salt and Boron Stress in the Hyper-Arid Lluteño Maize from
								Atacama Desert</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26681689" target="_blank">Transcriptional regulatory networks in Arabidopsis thaliana during single and combined stresses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23753177" target="_blank">Simultaneous application of heat, drought, and virus to Arabidopsis plants reveals significant shifts in
								signaling networks</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=21190570" target="_blank">Individual vs. combinatorial effect of elevated CO2 conditions and salinity stress on Arabidopsis
								thaliana liquid cultures: comparing the early molecular response using time-series transcriptomic
								and metabolomic analyses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23447525" target="_blank">Transcriptome responses to combinations of stresses in Arabidopsis</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26881808" target="_blank">Physiological and Transcriptome Responses to Combinations of Elevated CO2 and Magnesium in
								Arabidopsis thaliana.</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=32442250" target="_blank">Molecular plant responses to combined abiotic stresses put a spotlight on unknown and abundant
								genes.</a>&nbsp;&nbsp;&nbsp;
							<br /><br />

						</div>
					</div> */}



					{/* <div id="marqueecontainer" ref={el}>
						<div id="vmarquee" style={{ position: 'absolute', width: '98%', top: "-1096px" }}>
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33371388" target="_blank">Comparative Transcriptome Analysis of Iron and Zinc Deficiency in Maize ( Zea mays L.)</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=33582802" target="_blank">Transcription-associated metabolomic adjustments in maize occur during combined drought and
								cold stress</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=29558449" target="_blank">Long Non-Coding RNAs Responsive to Salt and Boron Stress in the Hyper-Arid Lluteño Maize from
								Atacama Desert</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26681689" target="_blank">Transcriptional regulatory networks in Arabidopsis thaliana during single and combined stresses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23753177" target="_blank">Simultaneous application of heat, drought, and virus to Arabidopsis plants reveals significant shifts in
								signaling networks</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=21190570" target="_blank">Individual vs. combinatorial effect of elevated CO2 conditions and salinity stress on Arabidopsis
								thaliana liquid cultures: comparing the early molecular response using time-series transcriptomic
								and metabolomic analyses</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=23447525" target="_blank">Transcriptome responses to combinations of stresses in Arabidopsis</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26881808" target="_blank">Physiological and Transcriptome Responses to Combinations of Elevated CO2 and Magnesium in
								Arabidopsis thaliana.</a>&nbsp;&nbsp;&nbsp;<br /><br /><br />
							<a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=32442250" target="_blank">Molecular plant responses to combined abiotic stresses put a spotlight on unknown and abundant
								genes.</a>&nbsp;&nbsp;&nbsp;
							<br /><br />

						</div>
					</div> */}

				</ul>
			</div>

			<div class="box">
				<h2>Statistics</h2>
				<ul class="popular">
					<table style={{ border: '1px black', width: '100%', borderCollapse: 'collapse' }} border="1"><colgroup><col width="50%" />
					</colgroup>
						<thead>
							<tr>
								<td style={{ padding: '3px' }}><b>Entity</b></td>
								<td style={{ padding: '3px' }}><center>Arabidopsis</center></td>
								<td style={{ padding: '3px' }}><center>Maize</center></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{ padding: '3px' }}><b>Gene Entries</b></td>
								<td style={{ padding: '3px' }}><center>32685</center></td>
								<td style={{ padding: '3px' }}><center>11742</center></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b>Pubmed Entries</b></td>
								<td style={{ padding: '3px' }}><center>433</center></td>
								<td style={{ padding: '3px' }}><center>81</center></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b>Individual stresses</b></td>
								<td style={{ padding: '3px' }}><center>108</center></td>
								<td style={{ padding: '3px' }}><center>17</center></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b>Combined stresses</b></td>
								<td style={{ padding: '3px' }}><center>44</center></td>
								<td style={{ padding: '3px' }}><center>4</center></td>
							</tr>

						</tbody>
					</table>
				</ul>
			</div>

			<div class="box">
				<h2>Links</h2>
				<ul class="popular">
					<table style={{ border: '1px black', width: '100%', borderCollapse: 'collapse' }} border="0"><colgroup><col width="50%" />
					</colgroup><tbody><tr>
						<td style={{ padding: '3px' }}><b><a href="https://www.arabidopsis.org/" target="_blank">TAIR</a></b></td>
						<td style={{ padding: '3px' }}><b><a href="https://www.maizegdb.org/" target="_blank">MaizeGDB</a></b></td>
					</tr>
							<tr>
								<td style={{ padding: '3px' }}><b><a href="https://plants.ensembl.org/index.html" target="_blank">Ensembl Plants</a></b></td>
								<td style={{ padding: '3px' }}><b><a href="https://www.uniprot.org/" target="_blank">Uniprot</a></b></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b><a href="https://www.ncbi.nlm.nih.gov/" target="_blank">NCBI</a></b></td>
								<td style={{ padding: '3px' }}><b><a href="http://planttfdb.gao-lab.org/" target="_blank">PlantTFDB</a></b></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b><a href="https://string-db.org/" target="_blank">String</a></b></td>
								<td style={{ padding: '3px' }}><b><a href="http://www.genome.jp/kegg/" target="_blank">KEGG</a></b></td>
							</tr>
							{/* <tr>
								<td style={{ padding: '3px' }}><b><a href="http://string-db.org/" target="_blank">STRING</a></b></td>
								<td style={{ padding: '3px' }}><b><a href="http://cancer.sanger.ac.uk/cancergenome/projects/cosmic/" target="_blank">COSMIC</a></b></td>
							</tr>
							<tr>
								<td style={{ padding: '3px' }}><b><a href="http://www.hprd.org/" target="_blank">HPRD</a></b></td>
								<td style={{ padding: '3px' }}><b><a href="http://www.ncbi.nlm.nih.gov/omim" target="_blank">OMIM</a></b></td>
							</tr> */}

						</tbody></table>
				</ul>
			</div>
		</div>
	);
}

export default Sidebar;
