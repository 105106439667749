import React from "react";

function Footer() {
  return (
    // <div className="footer">
    //   <footer class="py-5 bg-dark fixed-bottom">
    //     <div class="container">
    //       <p class="m-0 text-center text-white">
    //         Copyright &copy; Your Website 2020
    //       </p>
    //     </div>
    //   </footer>
    // </div>

    <div id="footer">
      <h3 class="l"> Copyright &copy; <a href="https://www.iari.res.in/" target="_blank"> IARI </a>
      </h3>
    </div>
  );
}

export default Footer;
